<template>
  <div class="mx-auto secondary--text">
    <div style="width: 100%" class="pb-4 mx-auto text-left">
      <div class="custom-title pb-4">Rent Ledger</div>
      <div class="pb-4">
        We created a ledger for the terms that you entered in the verified
        complaint. This ledger will need to be updated as the matter goes
        forward.
      </div>

      <!-- Rent Ledger Start -->
      <div class="mt-8 mb-4">
        <v-row>
          <v-col class="font-weight-bold">
            <span class="mr-4">Complaint Drafted Date:</span>
            {{ $dayjs(currentCase.vcDateDrafted).format("MM/DD/YYYY") }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            Monthly Rent: {{ convertToCurrency(currentCase.rentAmount) }}
          </v-col>
          <v-col cols="6">
            Rent Due Date: {{ formatDay(currentCase.rentDueBy) }}
          </v-col>
        </v-row>
      </div>
      <div class="ledger-table" style="max-height: 420px; overflow: auto">
        <vue-scroll :ops="ops">
          <v-data-table
            :headers="headers"
            :items="ledgerData"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            disable-sort
          >
            <template v-slot:item.date="props">
              <span :class="props.item.nextMonth ? 'font-weight-bold' : ''">{{
                props.item.date
              }}</span>
            </template>
            <template v-slot:item.details="props">
              <span :class="props.item.nextMonth ? 'font-weight-bold' : ''">{{
                props.item.details
              }}</span>
            </template>
            <template v-slot:item.amount="props">
              <div
                class="d-flex"
                :class="props.item.nextMonth ? 'font-weight-bold' : ''"
              >
                <div>
                  {{
                    !props.item.amount
                      ? ""
                      : convertToCurrency(props.item.amount)
                  }}
                </div>
              </div>
            </template>
            <template v-slot:item.payment="props">
              <div class="d-flex">
                <v-edit-dialog
                  v-if="props.item.payment"
                  large
                  persistent
                  @save="savePayAmount()"
                >
                  <div :class="props.item.nextMonth ? 'font-weight-bold' : ''">
                    {{ convertToCurrency(0 - parseFloat(props.item.payment)) }}
                    <v-icon
                      v-if="!currentCase.vcDateConsentDrafted"
                      size="14"
                      color="#4dccc4"
                      class="ml-1"
                    >
                      $pencil
                    </v-icon>
                  </div>
                  <template v-slot:input>
                    <div class="mt-4">Change amount</div>
                    <v-text-field
                      v-model="props.item.payment"
                      label="Edit"
                      single-line
                      autofocus
                      type="number"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
                <v-icon
                  v-if="!currentCase.vcDateConsentDrafted && props.item.payment"
                  size="18"
                  color="#4dccc4"
                  class="ml-1 mt-1"
                  @click="clearPayAmount(props.item)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </template>
            <template v-slot:item.totalBalance="props">
              <span :class="props.item.nextMonth ? 'font-weight-bold' : ''">{{
                convertToCurrency(props.item.totalBalance)
              }}</span>
            </template>
          </v-data-table>
        </vue-scroll>
      </div>
      <div class="d-flex align-center mt-3 mb-3">
        <div class="ml-1 info--text text-uppercase">
          <v-btn
            class="btn--plain"
            color="accent"
            icon
            tile
            :disabled="Boolean(currentCase.vcDateConsentDrafted)"
            @click="addPayDialogOpen = true"
          >
            <v-icon style="font-size: 28px"> mdi-plus-circle-outline </v-icon>
          </v-btn>
          Add Payments
        </div>
      </div>

      <h2 class="text-right mt-4">
        Total due: {{ convertToCurrency(totalDue) }}
      </h2>

      <div class="mt-8 mb-4">
        <v-row>
          <v-col cols="4">Total Base Rent</v-col>
          <v-col cols="2">Late Fees</v-col>
          <v-col cols="2">Court Fees</v-col>
          <v-col cols="2">Attorney Fees</v-col>
          <v-col cols="2">Total Balance</v-col>
        </v-row>
        <v-row class="font-weight-bold">
          <v-col cols="4">{{ convertToCurrency(totalBaseRent) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(lateFee) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(courtFee) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(attorneyFee) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(totalDue) }}</v-col>
        </v-row>
      </div>
      <!-- Rent Ledger End -->
    </div>

    <div>
      <v-dialog
        v-model="addPayDialogOpen"
        value="''"
        max-width="1000"
        persistent
      >
        <AddPayDialog
          @close:dialog="addPayDialogOpen = false"
          @add="addPayment"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import AddPayDialog from "@/components/dialogs/AddPayDialog";

export default {
  name: "RentLedgerView",
  props: {
    currentCase: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    AddPayDialog,
  },
  data() {
    return {
      addPayDialogOpen: false,
      headers: [
        {
          text: "Date",
          align: "start",
          value: "date",
        },
        { text: "Details", value: "details" },
        { text: "Amount Due", value: "amount" },
        { text: "Amount Paid", value: "payment" },
        { text: "Total Balance", value: "totalBalance" },
      ],
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 100,
          easing: "easeInQuad",
        },
        rail: {
          background: "#EAEBEB",
          opacity: 1,
          size: "10px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "10px",
          minSize: 0.1,
        },
      },
      ledgerData: [],
      charges: [],
      payments: [],
      totalBaseRent: 0,
      attorneyFee: 0,
      courtFee: 0,
      lateFee: 0,
      totalDue: 0,
      nextMonthRent: {},
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    /* add data to table - START */
    this.charges = [];
    let chargeItem;

    const pastDueRent = this.currentCase.pastDueRent.filter(
      (value) => value.selected
    );
    for (const element of pastDueRent) {
      chargeItem = {};
      chargeItem.dateForSort = this.$dayjs(element.timePeriod).format(
        "YYYY-MM-DD"
      );
      chargeItem.date = this.$dayjs(element.timePeriod).format("MM/DD/YYYY");
      chargeItem.details = "Rent";
      chargeItem.amount = parseFloat(element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
      if (pastDueRent.indexOf(element) === 0) {
        this.nextMonthRent = {
          ...chargeItem,
          date: this.$dayjs(element.timePeriod)
            .add(1, "month")
            .format("MM/DD/YYYY"),
          dateForSort: this.$dayjs(element.timePeriod)
            .add(1, "month")
            .format("YYYY-MM-DD"),
          nextMonth: true,
        };
      }
    }

    for (const element of this.currentCase.lateCharges.filter(
      (value) => value.selected
    )) {
      chargeItem = {};
      chargeItem.dateForSort =
        element.timePeriod.length > 7
          ? element.timePeriod
          : element.timePeriod + "-01";
      chargeItem.date = this.$dayjs(element.timePeriod).format("MM/DD/YYYY");
      chargeItem.details = element.type;
      chargeItem.amount = parseFloat(element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
      this.lateFee += chargeItem.amount;
    }

    for (const element of this.currentCase.utilities.filter(
      (value) => value.selected
    )) {
      chargeItem = {};
      chargeItem.dateForSort =
        element.timePeriod.length > 7
          ? element.timePeriod
          : element.timePeriod + "-01";
      chargeItem.date = this.$dayjs(element.timePeriod).format("MM/DD/YYYY");
      chargeItem.details = element.type;
      chargeItem.amount = parseFloat(element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
    }

    for (const element of this.currentCase.fees.filter(
      (value) => value.selected
    )) {
      chargeItem = {};
      chargeItem.dateForSort =
        element.timePeriod.length > 7
          ? element.timePeriod
          : element.timePeriod + "-01";
      chargeItem.date = this.$dayjs(element.timePeriod).format("MM/DD/YYYY");
      chargeItem.details = element.type;
      chargeItem.amount = parseFloat(element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
    }

    this.payments = this.currentCase.pastDueRentPayments || [];

    this.applyChangeInCharge();
  },
  methods: {
    async saveData() {
      await this.$store.dispatch("eviction/updateCase", {
        caseData: {
          pastDueRentPaid: this.totalDue,
          pastDueRentPayments: this.payments,
        },
        status: this.currentCase.status,
      });
    },
    formatDay(day) {
      const number = parseInt(day);
      let suffix;
      if (number > 3 && number < 21) suffix = "th";
      switch (number % 10) {
        case 1:
          suffix = "st";
          break;
        case 2:
          suffix = "nd";
          break;
        case 3:
          suffix = "rd";
          break;
        default:
          suffix = "th";
          break;
      }
      return day + suffix;
    },
    applyChangeInCharge() {
      this.payments = this.payments.map((item) => {
        return {
          ...item,
          dateForSort: this.$dayjs(item.date).format("YYYY-MM-DD"),
          amount: 0,
        };
      });
      this.ledgerData = [...this.charges, ...this.payments];
      const today = this.$dayjs().format("MM-DD");
      const rentDate = `${this.$dayjs(this.nextMonthRent.date).format("MM")}
                        -${
                          parseInt(this.currentCase.rentDueBy) > 9
                            ? this.currentCase.rentDueBy
                            : `0${this.currentCase.rentDueBy}`
                        }`;
      if (today >= rentDate && !this.ledgerData.includes(this.nextMonthRent)) {
        this.ledgerData.push(this.nextMonthRent);
      }

      this.ledgerData.sort((a, b) =>
        a.dateForSort > b.dateForSort
          ? 1
          : b.dateForSort > a.dateForSort
          ? -1
          : 0
      );
      this.totalBaseRent = this.ledgerData.reduce(
        (result, item) => result + item.amount - item.payment,
        0
      );

      if (this.currentCase.legalFeesPermitted) {
        const attorneyFee = this.currentCase.legalFees.find(
          (item) => item.type === "Attorney Fee"
        );
        this.attorneyFee =
          attorneyFee && attorneyFee.selected ? attorneyFee.amount : 0;
        this.ledgerData.push({
          details: "Attorney Fees",
          amount: parseFloat(this.attorneyFee),
          payment: 0,
          totalBalance: 0,
        });
        this.courtFee = this.currentCase.legalFees
          .filter((item) => item.type !== "Attorney Fee")
          .reduce((result, item) => result + parseFloat(item.amount), 0);
        this.ledgerData.push({
          details: "Court Fees",
          amount: parseFloat(this.courtFee),
          payment: 0,
          totalBalance: 0,
        });
      }

      this.totalDue = this.ledgerData.reduce(
        (result, item) => result + item.amount - item.payment,
        0
      );

      const ledgerData = this.ledgerData.slice();
      for (let i = 0; i < ledgerData.length; i++) {
        if (i === 0) {
          ledgerData[i].totalBalance =
            ledgerData[i].amount - ledgerData[i].payment;
        } else {
          ledgerData[i].totalBalance =
            ledgerData[i - 1].totalBalance +
            ledgerData[i].amount -
            ledgerData[i].payment;
        }
      }
      this.ledgerData = ledgerData;
      this.saveData();
    },
    addPayment(newPayments) {
      this.payments = [...this.payments, ...newPayments];
      this.addPayDialogOpen = false;
      this.applyChangeInCharge();
    },
    savePayAmount() {
      this.applyChangeInCharge();
    },
    clearPayAmount(item) {
      const index = this.payments.indexOf(item);
      this.payments.splice(index, 1);
      this.applyChangeInCharge();
    },
  },
};
</script>

<style scoped>
.ledger-table {
  border-top: 1px solid lightgray !important;
}
</style>
