import { store } from "../store";

export default (theCase) => {
  const currentCase = theCase || store.getters["eviction/currentCase"];
  const steps = store.getters["eviction/caseSteps"];

  if (
    currentCase.actions?.length &&
    currentCase.actions[0].actionStringId !== "completeVerifiedComplaint"
  ) {
    //* user is editing a already completed case
    steps.verifiedComplaint.status = "completed";
    Object.keys(steps.verifiedComplaint.children).forEach((key) => {
      steps.verifiedComplaint.children[key].status = "completed";
    });

    const actionId = currentCase.actions[0].actionId;
    if (actionId !== 191) {
      if (actionId >= 21) {
        // Enter Court Information
        steps.filedVerifiedComplaint.status = "completed";
        steps.clrs.status = "completed";
        if (actionId >= 31) {
          // Enter Trial Information
          // steps.courtInformation.status = "completed";
          if (actionId >= 41) {
            // Court Outcome
            if (currentCase.trialDate && currentCase.trialTime)
              steps.trialDate.status = "completed";
            if (actionId >= 51) {
              // Post Trial Actions
              // steps.courtOutcome.status = "completed";
              // steps.updateLedgerBeforeCourtOutcome.status = "completed";
              if (actionId >= 61) {
                // Request for Warrant Of Removal
                steps.updateLedgerBeforeTrialOutcome.status = "completed";
                steps.trialOutcome.status = "completed";
                steps.requestWOR.status = "completed";
                Object.keys(steps.trialOutcome.children).forEach((key) => {
                  steps.trialOutcome.children[key].status = "completed";
                });
              }
            }
          }
        }
      }
    }

    store.commit("eviction/update_steps", steps);
  }

  //* Handle property steps
  const property = store.getters["property/currentProperty"];
  const { status: propertyStatus, completed: propertyCompleted } = property;

  const propertySteps = steps.verifiedComplaint.children.property.children;
  if (propertyCompleted) {
    steps.verifiedComplaint.children.property.status = "completed";
    Object.keys(propertySteps).forEach((child_key) => {
      propertySteps[child_key].status = "completed";
    });
  } else {
    //* Some property steps not yet completed
    const propertyStatuses = ["contact", "address", "type", "owner"];
    const onlyCompleted = propertyStatuses.filter(
      (status, index) => index <= propertyStatuses.indexOf(propertyStatus)
    );
    if (onlyCompleted)
      Object.keys(propertySteps).forEach((child_key) => {
        if (onlyCompleted.includes(child_key))
          propertySteps[child_key].status = "completed";
      });
  }

  const actionId = currentCase.actions?.length
    ? currentCase.actions[0].actionId
    : null;
  console.log(currentCase);

  if (actionId === 181 && currentCase.status.includes("vc")) {
    const vcSteps = steps.verifiedComplaint.children;
    const statusIndex = Object.keys(vcSteps).indexOf(
      currentCase.status.split("-")[1]
    );
    Object.keys(vcSteps).forEach((key, index) => {
      if (index <= statusIndex) {
        steps.verifiedComplaint.children[key].status = "completed";
      }
    });
  } else if (
    (actionId === 41 || actionId === 51) &&
    currentCase.status.includes("wor")
  ) {
    const worSteps = steps.trialOutcome.children;
    const statusIndex = Object.keys(worSteps).indexOf(
      currentCase.status.split("-")[1]
    );
    Object.keys(worSteps).forEach((key, index) => {
      if (index <= statusIndex) {
        steps.trialOutcome.children[key].status = "completed";
      }
    });
  } else {
    const statusIndex = Object.keys(steps).indexOf(currentCase.status);
    Object.keys(steps).forEach((key, index) => {
      if (index <= statusIndex) {
        steps[key].status = "completed";
      }
    });
  }

  store.commit("eviction/update_steps", steps);
};
