import { render, staticRenderFns } from "./ConsentLedger.vue?vue&type=template&id=78325bac&scoped=true&"
import script from "./ConsentLedger.vue?vue&type=script&lang=js&"
export * from "./ConsentLedger.vue?vue&type=script&lang=js&"
import style0 from "./ConsentLedger.vue?vue&type=style&index=0&id=78325bac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78325bac",
  null
  
)

export default component.exports