<template>
  <svg viewBox="0 0 37.045 37.203">
    <path
      class="a svg-icon"
      d="M92.87,66.148c0-.548.009-1.063,0-1.586a2.269,2.269,0,0,0-1.62-2.155,2.363,2.363,0,0,0-2.61.795,2.411,2.411,0,0,0-.5,1.535v1.386a.4.4,0,0,1-.4.4h-2.5a.4.4,0,0,1-.4-.4c0-.476,0-.935,0-1.4a2.369,2.369,0,0,0-4.736-.149c-.013.51-.009,1.025,0,1.539a.4.4,0,0,1-.4.4H77.111a.4.4,0,0,1-.4-.4,14.179,14.179,0,0,0-.021-1.9,2.1,2.1,0,0,0-1.547-1.8,2.288,2.288,0,0,0-2.325.459A2.366,2.366,0,0,0,72,64.83c0,.434,0,.867,0,1.322a.4.4,0,0,1-.4.4h-7a.4.4,0,0,0-.4.4V99.077a.4.4,0,0,0,.4.4h36.237a.4.4,0,0,0,.4-.4V66.947a.4.4,0,0,0-.4-.4H93.274A.4.4,0,0,1,92.87,66.148ZM75.308,97.9H66.219a.4.4,0,0,1-.4-.4V91.14a.4.4,0,0,1,.4-.4h9.089a.4.4,0,0,1,.4.4v6.351A.4.4,0,0,1,75.308,97.9Zm0-8.787H66.219a.4.4,0,0,1-.4-.4V81.992a.4.4,0,0,1,.4-.4h9.093a.4.4,0,0,1,.4.4v6.717A.4.4,0,0,1,75.312,89.108Zm0-9.14h-9.1a.4.4,0,0,1-.4-.4V73.12a.4.4,0,0,1,.4-.4h9.1a.4.4,0,0,1,.4.4v6.449A.4.4,0,0,1,75.317,79.968ZM87.424,97.9H77.808a.4.4,0,0,1-.4-.4V91.161a.4.4,0,0,1,.4-.4h9.616a.4.4,0,0,1,.4.4V97.5A.4.4,0,0,1,87.424,97.9Zm.425-9.008c-.008.123-.191.221-.417.221H77.808a.4.4,0,0,1-.4-.4V81.992c0-.221.106-.4.23-.412l.23-.013c3.167,0,6.33.008,9.493-.009.383,0,.514.085.51.489-.017,2.206-.008,4.421-.013,6.628a1.869,1.869,0,0,1,0,.217Zm.013-9.161c0,.123-.183.225-.4.225H77.8a.4.4,0,0,1-.4-.4V73.094c0-.221.106-.4.238-.4h.238c3.167,0,6.33,0,9.493-.008.383,0,.51.089.5.489-.017,2.113-.008,4.217-.008,6.33v.225ZM89.894,81.6h9.314a.4.4,0,0,1,.4.4v6.713a.4.4,0,0,1-.4.4H89.894a.4.4,0,0,1-.4-.4V82a.4.4,0,0,1,.4-.4ZM99.212,97.9H89.894a.4.4,0,0,1-.4-.4V91.153a.4.4,0,0,1,.4-.4h9.319a.4.4,0,0,1,.4.4V97.5A.392.392,0,0,1,99.212,97.9Zm0-17.94H89.894a.4.4,0,0,1-.4-.4V73.124a.4.4,0,0,1,.4-.4h9.319a.4.4,0,0,1,.4.4v6.432A.392.392,0,0,1,99.212,79.955Z"
      transform="translate(-64.2 -62.274)"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
