<template>
  <svg viewBox="0 0 1000 1000" width="250">
    <path
      class="a svg-icon"
      d="M891.5 286.2h-70.39999999999998v-146.7c0-59.7-48.89999999999998-108.5-108.60000000000002-108.5h-425c-59.69999999999999 0-108.5 48.8-108.5 108.5v146.7h-70.5c-59.7 0-108.5 48.80000000000001-108.5 108.5v466.2c0 59.700000000000045 48.8 108.5 108.5 108.5h783c59.700000000000045 0 108.5-48.799999999999955 108.5-108.5v-466.2c0-59.69999999999999-48.799999999999955-108.5-108.5-108.5z m-177.10000000000002-90.29999999999998v89.1h-428.79999999999995v-102.4c0-29.900000000000006 24.399999999999977-54.29999999999998 54.299999999999955-54.29999999999998h320.20000000000005c29.899999999999977 0 54.299999999999955 24.399999999999977 54.299999999999955 54.29999999999998v13.300000000000011z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
