<template>
  <div>
    <LoadingOverlay active />
  </div>
</template>

<script>
export default {
  name: "Register",
  created() {
    this.$auth.loginWithRedirect({ screen_hint: "signup" });
  },
};
</script>
