<template>
  <div>
    <div class="px-16 sticky-header">
      <div class="d-flex align-center justify-space-between page-subheader">
        <div class="d-flex align-center">
          <div class="secondary--text custom-heading mr-12">Calendar</div>
          <v-btn
            fab
            depressed
            color="primary"
            height="45"
            width="45"
            @click="newEventDialog = true"
          >
            <span
              class="white--text font-weight-regular"
              style="font-size: 50px"
              >+</span
            >
          </v-btn>
        </div>

        <div class="ml-auto">
          <!-- search start -->
          <v-menu
            v-model="searchMenu"
            :close-on-content-click="false"
            :nudge-width="200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="px-4 subheader-btn ml-auto"
                v-bind="attrs"
                v-on="on"
              >
                <i class="icofont-search-1" color="grey" />&nbsp;Search
              </v-btn>
            </template>

            <v-card
              style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
            >
              <div class="px-8 py-4 table-custom-search">
                <input
                  v-model="search"
                  type="text"
                  placeholder="Search Events"
                />
              </div>
            </v-card>
          </v-menu>
          <!-- search end -->

          <!-- export start -->
          <v-btn class="ml-4 px-4 subheader-btn" @click="exportCalendar">
            <i class="icofont-external" />&nbsp;Export
          </v-btn>
          <!-- export end -->

          <!-- filter start -->
          <v-menu
            v-model="filterMenu"
            :close-on-content-click="false"
            :nudge-width="530"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-4 px-4 subheader-btn" v-bind="attrs" v-on="on">
                <v-icon size="xx-large" color="primary"
                  >icofont-dotted-down</v-icon
                >&nbsp;Filter By:
              </v-btn>
            </template>

            <v-card
              style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
            >
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <strong class="secondary--text">FILTER BY</strong>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list>
                <v-list-item v-for="(item, index) in filters" :key="index">
                  <v-list-item-action>
                    <v-icon @click="removeFilter(index)">mdi-close</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-select
                      v-model="item.filterColumn"
                      :items="item.columns"
                      class="mr-2"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 200px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                    <v-select
                      v-model="item.filterOperator"
                      :items="item.operators"
                      class="mr-2"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 150px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                    <v-select
                      v-model="item.filterValue"
                      :items="item.values"
                      class="mr-2"
                      solo
                      flat
                      dense
                      background-color="#F0F5F6"
                      style="max-width: 200px"
                    >
                      <v-icon slot="append" medium color="accent">
                        mdi-chevron-down
                      </v-icon>
                    </v-select>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-btn
                  class="btn--plain ml-12"
                  color="secondary"
                  icon
                  :disabled="!events.length"
                  @click="addFilter"
                >
                  <v-icon class="mr-2" color="accent" style="font-size: 28px"
                    >mdi-plus-circle-outline</v-icon
                  >
                  Add Filter
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-badge
            v-if="filterCount > 0"
            color="primary"
            overlap
            offset-y="-10"
          >
            <span slot="badge" style="font-size: 17px; font-weight: bold">{{
              filterCount
            }}</span>
          </v-badge>
          <!-- filter end -->

          <!-- add tags -->
          <v-menu
            v-model="tagMenu"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-4 px-4 subheader-btn" v-bind="attrs" v-on="on">
                <v-icon size="small" color="primary">icofont-ui-press</v-icon
                >&nbsp;Add Tags
              </v-btn>
            </template>

            <v-card
              class="tags-card"
              style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
            >
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <strong class="secondary--text">Tags</strong>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list style="width: 400px">
                <v-list-item v-for="(item, index) in tags" :key="index">
                  <v-list-item-content>
                    <div class="d-flex">
                      <v-icon class="mr-4" size="medium" :color="item.color"
                        >icofont-ui-press</v-icon
                      >
                      <span v-if="item.name" class="ml-2">{{ item.name }}</span>
                      <v-text-field
                        v-else
                        v-model="tempTagName"
                        placeholder="New Tag Name"
                        outlined
                        dense
                        style="height: 40px"
                      ></v-text-field>
                      <v-icon
                        v-if="item.name"
                        class="ml-auto mt-1 mr-4"
                        size="medium"
                        @click="editTag(index)"
                        >icofont-pencil-alt-2</v-icon
                      >
                      <v-icon
                        depressed
                        v-else
                        class="mt-2 mr-2"
                        @click="saveTag(index)"
                        >mdi-content-save-outline</v-icon
                      >
                      <v-icon v-if="item.name" @click="removeTag(item.id)"
                        >mdi-delete</v-icon
                      >
                      <v-icon v-else class="mt-2" @click="cancelEdit(index)"
                        >mdi-close</v-icon
                      >
                    </div>
                    <v-color-picker
                      v-if="!item.name"
                      class="ma-2 mt-0"
                      v-model="tempTagColor"
                      @input="onChangeColor(index)"
                      :swatches="swatches"
                      show-swatches
                      hide-canvas
                      hide-inputs
                      hide-sliders
                    ></v-color-picker>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-btn
                  class="btn--plain ml-12"
                  color="secondary"
                  icon
                  @click="addTag"
                >
                  <v-icon class="mr-2" color="accent" style="font-size: 28px"
                    >mdi-plus-circle-outline</v-icon
                  >
                  Add Tag
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
      </div>
    </div>

    <div class="mx-2" style="padding: 30px 50px 50px">
      <div>
        <v-tabs align-with-title color="#003a60" slider-size="4" class="mb-10">
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab @click="type = 'month'"> MONTH </v-tab>
          <v-tab @click="type = 'week'"> WEEK </v-tab>
          <v-tab @click="type = 'day'"> DAY </v-tab>
        </v-tabs>
        <v-row
          class="fill-height px-8"
          style="
            box-shadow: 15px 15px 40px #00000029 !important;
            border-radius: 10px !important;
          "
        >
          <v-col>
            <v-sheet height="64">
              <v-toolbar flat>
                <v-btn
                  outlined
                  class="mr-4"
                  color="grey darken-2"
                  @click="setToday"
                >
                  Today
                </v-btn>
                <v-btn
                  fab
                  text
                  small
                  style="margin-left: 30%"
                  color="grey darken-2"
                  @click="prev"
                >
                  <v-icon color="primary"> mdi-chevron-left </v-icon>
                </v-btn>
                <v-toolbar-title
                  class="secondary--text text-center"
                  style="width: 200px"
                  v-if="$refs.calendar"
                >
                  {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-btn fab text small @click="next">
                  <v-icon color="primary"> mdi-chevron-right </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
              </v-toolbar>
            </v-sheet>
            <v-sheet>
              <v-calendar
                ref="calendar"
                v-model="focus"
                :short-weekdays="false"
                color="primary"
                :events="events"
                :event-color="getEventColor"
                :type="type"
                @click:more="showMore"
                @change="updateRange"
              >
                <template v-slot:event="{ event }">
                  <div class="event-container">
                    <div class="d-flex">
                      <div
                        class="d-flex flex-column align-self-center ml-2"
                        style="width: 70%; overflow: hidden"
                      >
                        <span
                          style="
                            font-size: 20px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                          @click="goToCase(event)"
                          >{{ `${event.title} - ${event.county}` }}</span
                        >
                        <strong class="mt-2">{{
                          tConvert(event.startTime.slice(0, 5))
                        }}</strong>
                      </div>
                      <v-icon
                        class="ml-auto"
                        color="primary"
                        size="large"
                        @click="showEvent(event)"
                        >icofont-external-link</v-icon
                      >
                    </div>
                  </div>

                  <v-menu
                    v-if="event.index == 0"
                    v-model="moreEventMenu"
                    :close-on-content-click="false"
                    :position-x="x"
                    :position-y="y"
                    absolute
                    offset-y
                  >
                    <v-card
                      style="
                        box-shadow: 15px 15px 40px #00000029;
                        border-radius: 20px;
                      "
                    >
                      <v-list style="width: 400px">
                        <div
                          class="ml-4 d-flex flex-column secondary--text mb-2"
                          v-for="(evt, index) in events"
                          :key="index"
                        >
                          <p
                            v-if="index == 0"
                            class="ml-8"
                            style="font-size: 40px"
                          >
                            {{ evt.day }}
                          </p>
                          <div class="d-flex">
                            <div
                              :style="`font-size: 15px; border-left: 3px solid ${evt.color}; border-radius: 4px`"
                            >
                              {{
                                evt.startTime
                                  ? tConvert(evt.startTime.slice(0, 5))
                                  : ""
                              }}
                            </div>
                            <div
                              class="d-flex flex-column ml-2"
                              style="width: 80%"
                            >
                              <div class="d-flex">
                                <div
                                  style="
                                    font-size: 20px;
                                    white-space: nowrap;
                                    width: 80%;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    text-decoration: underline;
                                    cursor: pointer;
                                  "
                                  @click="goToCase(evt)"
                                >
                                  {{ `${evt.title} - ${evt.county}` }}
                                </div>
                                <v-icon
                                  class="ml-auto"
                                  color="primary"
                                  size="medium"
                                  @click="showEvent(evt)"
                                  >icofont-external-link</v-icon
                                >
                              </div>
                              <strong>{{ evt.title }}</strong>
                            </div>
                          </div>
                        </div>
                      </v-list>
                    </v-card>
                  </v-menu>
                </template>
              </v-calendar>
            </v-sheet>
          </v-col>
        </v-row>
      </div>

      <div v-if="todayEvents.length" class="mt-16" style="width: 40%">
        <p
          class="ml-16 mb-6 pl-8 secondary--text font-weight-medium"
          style="font-size: 20px"
        >
          Today's Events
        </p>
        <div class="py-0">
          <v-timeline align-top dense style="min-width: 400px">
            <v-timeline-item
              v-for="item in todayEvents"
              v-bind:key="item.eventId"
              color="primary"
              small
            >
              <v-row>
                <v-col cols="4">
                  <span class="font-weight-medium" style="font-size: 15px">{{
                    tConvert(item.startTime.slice(0, 5))
                  }}</span>
                </v-col>
                <v-col class="event-timeline secondary--text">
                  <strong>{{ item.caseParties }}</strong>
                  <div class="text-caption">
                    {{ item.title }}
                  </div>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>
        </div>
      </div>
    </div>

    <v-dialog v-model="newEventDialog" persistent max-width="700">
      <NewEventDialog
        @close="closeNewDialog"
        @saveNewEvent="saveNewEvent"
        type="new"
      />
    </v-dialog>

    <v-dialog
      v-model="selectedOpen"
      :close-on-content-click="false"
      offset-x
      persistent
      max-width="600"
    >
      <EventDetailDialog
        @close="closeDetailDialog"
        @deleteEvent="deleteEvent"
        :eventData="selectedEvent"
      />
    </v-dialog>
  </div>
</template>

<script>
import NewEventDialog from "@/components/dialogs/NewEventDialog.vue";
import EventDetailDialog from "@/components/dialogs/EventDetailDialog.vue";
import { mapGetters } from "vuex";

export default {
  name: "Calendar",
  components: {
    NewEventDialog,
    EventDetailDialog,
  },
  data: () => ({
    swatches: [
      ["#FF0000"],
      ["#AA0000"],
      ["#550000"],
      ["#FFFF00"],
      ["#AAAA00"],
      ["#555500"],
      ["#00FF00"],
      ["#00AA00"],
      ["#005500"],
    ],
    focus: "",
    type: "month",
    selectedEvent: {},
    selectedOpen: false,
    events: [],
    _events: [],
    newEventDialog: false,
    curDateRange: {},
    search: "",
    searchMenu: false,
    filters: [
      {
        columns: ["CASE PARTIES", "TITLE", "DETAILS", "TAG"],
        operators: [],
        values: [],
        filterColumn: "",
        filterOperator: "",
        filterValue: "",
      },
    ],
    filterMenu: false,
    tableData: [],
    tagMenu: false,
    originTagName: "",
    originTagColor: null,
    tempTagName: "",
    tempTagColor: null,
    moreEventMenu: false,
    x: 0,
    y: 0,
    icsFile: null,
  }),
  computed: {
    ...mapGetters({
      allEvents: "event/events",
      attorneysLoaded: "attorney/loaded",
      tags: "event/tags",
      tagsLoaded: "event/tagsLoaded",
      currentCase: "eviction/currentCase",
    }),
    todayEvents() {
      const todayDate = new Date();
      const todayEvents = this.allEvents.filter(
        (item) => item.date == todayDate.toISOString().split("T")[0]
      );
      todayEvents.sort((a, b) =>
        b.startTime < a.startTime ? 1 : a.startTime < b.startTime ? -1 : 0
      );

      return todayEvents;
    },
    filterColumns() {
      return this.filters.map((item) => item.filterColumn);
    },
    filterCount() {
      return this.filters.filter(
        (item) => item.filterColumn && item.filterOperator && item.filterValue
      ).length;
    },
  },
  watch: {
    filterColumns(newVal, oldVal) {
      if (newVal.length !== oldVal.length) return;
      const diffItem = newVal.find((item, index) => item !== oldVal[index]);
      const diffIndex = newVal.indexOf(diffItem);
      this.filters[diffIndex].operators = ["Equal To"];
      this.filters[diffIndex].filterOperator = "Equal To";
      this.filters[diffIndex].filterValue = "";
      this.filters[diffIndex].values = this.tableData.map(
        (item) => item[this.filters[diffIndex].filterColumn]
      );
      // }
    },
    search(value) {
      this.events = this._events.filter(
        (item) =>
          (item.caseParties &&
            item.caseParties.toLowerCase().includes(value.toLowerCase())) ||
          (item.title &&
            item.title.toLowerCase().includes(value.toLowerCase())) ||
          (item.details &&
            item.details.toLowerCase().includes(value.toLowerCase())) ||
          (item.tagName &&
            item.tagName.toLowerCase().includes(value.toLowerCase()))
      );
    },
    filters: {
      handler(val) {
        if (!val.length) {
          this.events = this._events;
          return;
        }
        const _filters = val.filter(
          (item) => item.filterColumn && item.filterOperator && item.filterValue
        );
        if (_filters.length) {
          let _filteredEvents = this._events;
          _filters.map((item) => {
            _filteredEvents = _filteredEvents.filter((eventItem) => {
              switch (item.filterColumn) {
                case "CASE PARTIES":
                  return eventItem.caseParties == item.filterValue;
                case "TITLE":
                  return eventItem.title == item.filterValue;
                case "DETAILS":
                  return eventItem.details == item.filterValue;
                case "TAG":
                  return eventItem.tag == item.filterValue;
              }
            });
          });
          this.events = _filteredEvents;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    this.$refs.calendar.checkChange();
    if (!this.attorneysLoaded) {
      await this.$store.dispatch("attorney/loadAttorneys");
      this.$store.commit("attorney/set_loaded", true);
    }
    if (!this.tagsLoaded) {
      await this.$store.dispatch("event/loadTags");
      this.$store.commit("event/set_tagsLoaded", true);
    }

    if (this.currentCase.creatingEvent) {
      this.newEventDialog = true;
    }
  },
  methods: {
    addFilterData() {
      this.events.map((item) => {
        this.tableData.push({
          "CASE PARTIES": item.caseParties,
          TITLE: item.title,
          DETAILS: item.details,
          TAG: item.tag,
        });
      });
    },
    convertDate(_date, _time) {
      let date = _date.split("-");
      date = date.join("");
      let time = _time.split(":");
      time = time.join("");
      return `${date}T${time}Z`;
    },
    makeIcsFile() {
      let events =
        "BEGIN:VCALENDAR\n" +
        "CALSCALE:GREGORIAN\n" +
        "METHOD:PUBLISH\n" +
        "PRODID:-//FileEviction Calendar//EN\n" +
        "VERSION:2.0\n";

      for (const item of this.events)
        events =
          events +
          "BEGIN:VEVENT\n" +
          `UID:FEC-${item.eventId}\n` +
          "DTSTART;VALUE=DATE:" +
          this.convertDate(item.date, item.startTime) +
          "\n" +
          "DTEND;VALUE=DATE:" +
          this.convertDate(item.date, item.endTime) +
          "\n" +
          "SUMMARY:" +
          `${item.title} - ${item.county}` +
          "\n" +
          "DESCRIPTION:" +
          item.details +
          "\n" +
          "END:VEVENT\n";

      events += "END:VCALENDAR";

      const data = new File([events], { type: "text/plain" });

      // If we are replacing a previously generated file we need to
      // manually revoke the object URL to avoid memory leaks.
      if (this.icsFile !== null) {
        window.URL.revokeObjectURL(this.icsFile);
      }

      this.icsFile = window.URL.createObjectURL(data);
    },
    exportCalendar() {
      this.makeIcsFile();
      const link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute("href", this.icsFile);
      link.setAttribute("download", "calendar.ics");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    removeFilter(index) {
      this.filters.splice(index, 1);
    },
    addFilter() {
      this.filters.push({
        columns: ["CASE PARTIES", "TITLE", "DETAILS", "TAG"],
        operators: [],
        values: [],
        filterColumn: "",
        filterOperator: "",
        filterValue: "",
      });
    },
    async removeTag(id) {
      if (!id) {
        this.tags.splice(this.tags.length - 1, 1);
        return;
      }
      await this.$store.dispatch("event/deleteTag", id);
    },
    addTag() {
      this.tempTagName = "";
      this.tags.push({
        color: "#FF0000",
        name: "",
      });
    },
    editTag(index) {
      this.originTagName = this.tags[index].name;
      this.originTagColor = this.tags[index].color;
      this.tempTagName = this.tags[index].name;
      this.tags[index].name = "";
    },
    cancelEdit(index) {
      this.tags[index].name = this.originTagName;
      this.tags[index].color = this.originTagColor;
    },
    onChangeColor(index) {
      this.tags[index].color = this.tempTagColor.hexa;
    },
    async saveTag(index) {
      this.tags[index].name = this.tempTagName;
      this.tags[index].color = this.tempTagColor.hexa;
      await this.$store.dispatch(
        `${this.tags[index].id ? "event/updateTag" : "event/saveNewTag"}`,
        this.tags[index]
      );
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    showMore(nativeEvent, pointerEvent) {
      pointerEvent.preventDefault();
      this.moreEventMenu = false;
      this.x = pointerEvent.clientX;
      this.y = pointerEvent.clientY;
      this.$nextTick(() => {
        this.moreEventMenu = true;
      });
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    async goToCase(event) {
      await this.$store.dispatch("eviction/loadCase", {
        caseId: event.caseId,
        forceLoad: true,
      });
      this.$router.push(`/case-view/${event.caseId}/overview`);
    },
    showEvent(event) {
      const open = () => {
        this.selectedEvent = event;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
    },
    async reloadEvents() {
      await this.$store.dispatch("event/loadEvents", this.curDateRange);
      const events = [];

      this.allEvents.map((item, idx) => {
        let timed = true;
        if (item.startTime == null) timed = false;
        events.push({
          index: idx,
          eventId: item.id,
          caseId: item.caseId,
          caseParties: item.caseParties,
          attorneyName: item.attorneyName,
          docketNo: item.docketNo,
          title:
            item.title === "Case Management Conference" ? "Case" : item.title,
          county: item.county,
          details: item.details,
          start: new Date(`${item.date}T${item.startTime}`),
          end: new Date(`${item.date}T${item.endTime}`),
          startTime: item.startTime,
          endTime: item.endTime,
          day: item.date.slice(8, 10),
          date: item.date,
          timed: timed,
          tag: item.tagName,
          color: item.tagColor,
          handlingConflict: false,
        });
      });
      this.events = events;
      this._events = events;
      this.addFilterData();
    },
    async updateRange({ start, end }) {
      const dateRange = {
        startDate: start.date,
        endDate: end.date,
      };

      this.curDateRange = dateRange;
      this.reloadEvents();
    },
    async saveNewEvent(newEvent) {
      this.newEventDialog = false;
      await this.$store.dispatch("event/saveEvent", {
        eventData: newEvent,
        isNewEvent: true,
      });
      this.reloadEvents();
    },
    async deleteEvent(eventId) {
      this.selectedOpen = false;
      await this.$store.dispatch("event/deleteEvent", eventId);
      this.reloadEvents();
    },
    closeNewDialog() {
      this.newEventDialog = false;
    },
    closeDetailDialog() {
      this.selectedOpen = false;
    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
      }
      return time.join(""); // return adjusted time or original string
    },
  },
};
</script>

<style>
.v-calendar .theme--light.v-btn:hover::before {
  opacity: 0;
}
.v-calendar-weekly__day-label {
  text-align: left !important;
}
.v-calendar-weekly__day-label .v-btn {
  font-size: 15px !important;
  cursor: default;
}
.v-calendar-weekly__day-label .v-btn::before {
  background-color: white;
}
.v-calendar-weekly__week {
  min-height: unset !important;
}
.v-calendar-weekly__day {
  height: 150px;
}
.v-calendar-weekly__day .v-event {
  background-color: white !important;
  color: #003a60 !important;
  margin-left: 3px;
  border-left: 3px solid green;
  height: 45px !important;
  font-size: 15px !important;
}
.start-time {
  writing-mode: vertical-lr;
  text-orientation: mixed;
  text-align: center;
  font-size: 13px;
}
.v-color-picker {
  max-width: unset !important;
}
.v-color-picker__color {
  width: 20px !important;
  height: 20px !important;
  border-radius: 20px !important;
}
</style>

<style scoped>
.v-icon.v-icon::after {
  background-color: white;
}
button.v-icon.v-icon {
  align-items: normal;
}
.v-color-picker >>> .v-color-picker__swatches > div {
  justify-content: left;
  margin-left: 10px;
}
.btn-category >>> span.v-btn__content {
  text-transform: lowercase !important;
}
.btn-category {
  padding: 0 !important;
}
.v-menu__content {
  background-color: white;
  margin-top: 40px;
  border-radius: 15px;
  box-shadow: 15px 15px 40px #00000029 !important;
  position: absolute;
  top: 5px;
}
.v-timeline-item {
  height: 100px;
}
.event-timeline {
  border-left: 2px solid black;
  padding: 0 0 0 10px;
  margin: 15px 0;
}
.v-timeline {
  padding-top: 0;
}
.v-timeline >>> .v-timeline-item__dot {
  background-color: #d7f4f2 !important;
  box-shadow: none;
}
.v-timeline >>> .v-timeline-item__dot--small .v-timeline-item__inner-dot {
  height: 14px !important;
  margin: 5px !important;
  width: 14px !important;
}
.v-calendar >>> .v-calendar-weekly__head-weekday {
  font-size: 18px;
  color: #003a60 !important;
  font-weight: 400 !important;
  text-transform: none !important;
}
.v-select >>> .v-text-field__details {
  display: none;
}
.tags-card .v-input >>> .v-input__slot {
  width: 85% !important;
}
.v-calendar-weekly__day .v-event-more {
  position: relative;
}
.v-calendar >>> .v-calendar-daily__day .v-event-timed {
  border: none !important;
  padding-top: 3px;
  color: #003a60 !important;
}
.v-calendar >>> .v-calendar-daily__day .v-event-timed .event-container {
  background-color: white !important;
  height: 50px;
}
.v-calendar-daily__day .v-event-timed .start-time {
  display: none;
}
</style>
