<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div style="width: 80%" class="pt-10 pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-4">Confirmation</div>
      <div class="pb-6">
        The statements below were pre-selected for the most common scenarios.
        Review each and deselect as applicable, so that the statements below
        match your client's specific situation.
      </div>

      <v-row class="my-4">
        <v-col cols="1" class="pa-0">
          <div class="caption info--text font-weight-medium">TRUE</div>
        </v-col>
        <v-col cols="1" class="pa-0">
          <div class="caption info--text font-weight-medium">FALSE</div>
        </v-col>
      </v-row>
      <div v-for="statement in statements" :key="statement.text">
        <v-row class="mb-4">
          <v-col cols="2" class="pa-0">
            <v-radio-group
              v-model="statement.confirmed"
              class="my-0 primary-radio-group"
              :mandatory="true"
              hide-details
              row
            >
              <v-radio
                :value="true"
                on-icon="mdi-checkbox-blank-circle"
                class="ml-4"
              />
              <v-radio
                color="primary"
                :value="false"
                on-icon="mdi-checkbox-blank-circle"
                class="pa-0 mr-0 ml-1"
              />
            </v-radio-group>
          </v-col>
          <v-col class="pt-0">
            <div
              class="secondary--text text-left"
              style="font-size: 0.9rem; display: inline"
              v-html="statement.text"
            />
            <v-tooltip
              v-if="statement.info"
              v-model="statement.info.show"
              top
              :open-on-hover="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  retain-focus-on-click
                  class="ml-1"
                  style="display: inline-block"
                  @click="handleTooltip(statement.info)"
                  @blur="on.blur"
                >
                  <i class="icofont-question-circle accent2--text" />
                </v-btn>
              </template>
              <speech-bubble :title="statement.info.title">
                <span slot="htmlBody" v-html="statement.info.text" />
              </speech-bubble>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row
          v-if="statement.confirmed === statement.warningIfEquals"
          class="mb-4"
        >
          <v-col cols="2" />
          <v-col class="pa-4 d-flex" cols="10" style="margin-top: -16px">
            <div class="side-border" style="background-color: #ff854e" />
            <div class="py-1 pl-4 flex-grow-1">
              <div
                class="secondary--text text-left pl-4 py-2"
                style="font-size: 0.9rem; border: 3px solid #ff854e"
              >
                {{
                  statement.warningMessage
                    ? statement.warningMessage
                    : `Your response is atypical, and may affect your ability to
                proceed with the evictions process. We strongly advise you to
                consult with an attorney. By proceeding, you accept all
                responsibility.`
                }}
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="back"
      >
        go back
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        @click="next"
      >
        continue
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Confirmation",
  data() {
    return {
      tooltipShowIndex: "",
      statements: {},
    };
  },
  computed: {
    ...mapGetters({
      case: "eviction/currentCase",
    }),
  },
  activated() {
    window.scrollTo(0, 0);
    this.initializeStatements();
  },
  deactivated() {
    this.saveData();
  },
  methods: {
    initializeStatements() {
      const { confirmation } = this.case;
      this.statements = {
        military_tenant: {
          text: "To your knowledge, no tenant in the property is a member of the United States Armed Forces, and the premises is not used for dwelling of the spouse, a child or other dependents of a person in the military.",
          confirmed: confirmation.military_tenant ?? true,
          warningIfEquals: false,
          warningMessage:
            "Your response will affect Section C(4) of the Certification by Landlord.",
        },
        registered_leasehold: {
          text: 'The landlord has registered the leasehold and notified tenant as required by <a href="https://law.justia.com/codes/new-jersey/2013/title-46/section-46-8-27/" target="_blank" style="color: #6a7fdb; display: inline">N.J.S.A. 46:8-27</a>.',
          confirmed: confirmation.registered_leasehold ?? true,
          warningIfEquals: false,
          info: {
            id: "registered_leasehold",
            show: false,
            text: `Select “True” if the landlord has registered the property either with the Bureau of Housing Inspection in the Department of Community Affairs (DCA)
            (if the property has three or more residential units in a single building) or with the municipal clerk
            (if the property is a one-dwelling unit rental or a two-dwelling unit non-owner occupied premises).
            Otherwise, select “False.” N.J. Stat. § 46:8-28; 28.5`,
          },
          warningMessage:
            "Your response will affect Section 11 of the Verified Complaint and Section C(2) of the Certification by Landlord.",
        },
        ownership: {
          text: "The landlord has acquired ownership of the property from the tenants.",
          confirmed: confirmation.ownership ?? false,
          warningIfEquals: true,
          info: {
            id: "ownership",
            show: false,
            text: `Select “True” if the property was previously owned by the tenants, and now they rent the property from you. This could be due to a foreclosure where the bank foreclosed on the property. Otherwise, select “False.”`,
          },
          warningMessage:
            "Your response will affect Section 3 of the Verified Complaint and Section C(3) of the Certification by Landlord.",
        },
        option_to_purchase: {
          text: "The landlord has given the tenant(s) the option to purchase the property.",
          confirmed: confirmation.option_to_purchase ?? false,
          warningIfEquals: true,
          info: {
            id: "option_to_purchase",
            show: false,
            text: "This is a rent to buy option. Select “True” if the tenant has the option to purchase the property as agreed upon in the Lease.",
          },
          warningMessage:
            "Your response will affect Section 4 of the Verified Complaint and Section C(3) of the Certification by Landlord.",
        },
      };
    },
    saveData() {
      const { statements } = this;
      const confirmation = {
        military_tenant: statements.military_tenant.confirmed,
        registered_leasehold: statements.registered_leasehold.confirmed,
        ownership: statements.ownership.confirmed,
        option_to_purchase: statements.option_to_purchase.confirmed,
      };
      this.$store.dispatch("eviction/updateCase", {
        caseData: {
          confirmation,
        },
        status: "vc-confirmation",
      });
    },
    next() {
      this.$router.push({ name: "vc-verification" });
    },
    back() {
      let prevRouteName = "vc-legal-fees";
      if (this.case.section8) {
        prevRouteName = this.case.additionalChargesPermitted
          ? "vc-additional-charges-extended"
          : "vc-additional-charges";
      }
      this.$router.push({
        name: prevRouteName,
      });
    },
    handleTooltip(params) {
      this.$set(this.statements[params.id].info, "show", true);
    },
  },
};
</script>

<style>
.primary-radio-group i.v-icon.notranslate.mdi.mdi-radiobox-blank.theme--light {
  color: #4dccc4;
}
.v-tooltip__content {
  pointer-events: initial;
  font-size: 20px;
  line-height: 26px;
}
</style>
