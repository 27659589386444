<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div style="width: 80%" class="pt-15 pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-4">Rent During the Consent Term</div>
      <div class="pb-4">
        The following table reflects the rental amounts due and owing during the
        term of the Consent Agreement. If unpaid, these rent payments can be
        reported as a breach.
      </div>
      <div class="pb-4">
        You may edit the Due Date and the Amount, or add additional Rent
        amounts.
      </div>

      <div style="max-height: 420px; overflow: auto">
        <vue-scroll :ops="ops">
          <v-data-table
            :headers="headers"
            :items="consentTermRents"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            disable-sort
          >
            <template v-slot:item.dueDate="props">
              <div class="d-flex">
                <v-edit-dialog v-if="props.item.dueDate" large persistent>
                  <div>
                    {{ props.item.dueDate }}
                    <v-icon size="14" color="#4dccc4" class="ml-1">
                      $pencil
                    </v-icon>
                  </div>
                  <template v-slot:input>
                    <div class="my-4">Change Due Date</div>
                    <v-menu
                      v-model="props.item.menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="props.item.dueDate"
                          append-icon="$calendar"
                          color="accent"
                          readonly
                          rounded
                          solo
                          flat
                          dense
                          background-color="#F0F5F6"
                          v-on="on"
                          @click:append="props.item.menu = true"
                        />
                      </template>
                      <v-date-picker
                        v-model="props.item.selectedDueDate"
                        no-title
                        :show-current="false"
                        color="primary"
                        class="custom-date"
                        @input="changeDueDate(props.item)"
                      />
                    </v-menu>
                  </template>
                </v-edit-dialog>
              </div>
            </template>
            <template v-slot:item.amount="props">
              <div class="d-flex">
                <v-edit-dialog large persistent>
                  <div>
                    {{ convertToCurrency(props.item.amount) }}
                    <v-icon size="14" color="#4dccc4" class="ml-1">
                      $pencil
                    </v-icon>
                  </div>
                  <template v-slot:input>
                    <div class="mt-4">Change amount</div>
                    <v-text-field
                      v-model="props.item.amount"
                      label="Edit"
                      single-line
                      autofocus
                      type="number"
                      :rules="[(v) => !!v || 'This value is required']"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </div>
            </template>
          </v-data-table>
        </vue-scroll>
      </div>
    </div>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="goBack"
      >
        Go Back
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        :disabled="!valid"
        @click="next"
      >
        Continue
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { courtOutcomesString, trialActionsString } from "@/utils/worFlowData";

export default {
  name: "RentConsentTerm",
  data() {
    return {
      valid: true,
      headers: [
        { text: "Rent for Month of", align: "start", value: "rentMonth" },
        { text: "Due Date", value: "dueDate" },
        { text: "Amount", value: "amount" },
      ],
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 100,
          easing: "easeInQuad",
        },
        rail: {
          background: "#EAEBEB",
          opacity: 1,
          size: "10px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "10px",
          minSize: 0.1,
        },
      },
      consentTermRents: [],
      consentTermRentsBackup: [],
      tenantPayFutureRent: false,
    };
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
  },
  activated() {
    window.scrollTo(0, 0);
    if (this.$route.name.includes("trial")) {
      if (
        trialActionsString().slice(2, 5).includes(this.currentCase.trialAction)
      ) {
        this.consentTermRents = this.currentCase.consentTermRents.filter(
          (item) => item.happened
        );
        this.consentTermRentsBackup = this.currentCase.consentTermRents.filter(
          (item) => !item.happened
        );
      } else {
        this.consentTermRents = this.currentCase.consentTermRents;
      }
    } else {
      if (this.currentCase.courtOutcome === courtOutcomesString()[3]) {
        this.consentTermRents = this.currentCase.consentTermRents.filter(
          (item) => item.happened
        );
        this.consentTermRentsBackup = this.currentCase.consentTermRents.filter(
          (item) => !item.happened
        );
      } else {
        this.consentTermRents = this.currentCase.consentTermRents;
      }
    }
  },
  deactivated() {
    this.saveData();
  },
  methods: {
    async saveData() {
      let consentTermRents = this.consentTermRents;
      if (this.$route.name.includes("trial")) {
        if (
          trialActionsString()
            .slice(2, 5)
            .includes(this.currentCase.trialAction)
        ) {
          consentTermRents = [
            ...this.consentTermRents,
            ...this.consentTermRentsBackup,
          ];
          consentTermRents.sort((a, b) =>
            a.selectedDueDate > b.selectedDueDate ? 1 : -1
          );
        }
      } else {
        if (this.currentCase.courtOutcome === courtOutcomesString()[3]) {
          // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
          consentTermRents = [
            ...this.consentTermRents,
            ...this.consentTermRentsBackup,
          ];
          consentTermRents.sort((a, b) =>
            a.selectedDueDate > b.selectedDueDate ? 1 : -1
          );
        }
      }
      await this.$store.dispatch("eviction/updateCase", {
        caseData: {
          consentTermRents,
        },
        status: this.$route.name.includes("trial")
          ? "wor-rentConsentTerm"
          : "co-rentConsentTerm",
      });
    },
    next() {
      if (this.$route.name.includes("trial")) {
        switch (this.currentCase.trialAction) {
          case trialActionsString()[2]: // Request for Warrant of Removal - Breach of Consent - Pay and Stay
          case trialActionsString()[3]: // Request for Warrant of Removal - Breach of Consent - Vacate with Payment Plan
          case trialActionsString()[4]: // Request for Warrant of Removal - Breach of Consent - Vacate without Payment Plan
            this.$router.push({ name: "wor-trial-confirm-breach" });
            break;
          case trialActionsString()[5]: // Executed Consent to Enter Judgment - Tenant Pays and Stays
          case trialActionsString()[6]: // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
            this.$router.push({ name: "wor-trial-additional-condition" });
            break;
        }
      } else {
        this.$router.push({ name: "wor-additional-condition" });
      }
    },
    goBack() {
      if (this.$route.name.includes("trial")) {
        switch (this.currentCase.trialAction) {
          case trialActionsString()[2]: // Request for Warrant of Removal - Breach of Consent - Pay and Stay
          case trialActionsString()[3]: // Request for Warrant of Removal - Breach of Consent - Vacate with Payment Plan
          case trialActionsString()[5]: // Executed Consent to Enter Judgment - Tenant Pays and Stays
          case trialActionsString()[6]: // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
            this.$router.push({ name: "wor-trial-payment-plan" });
            break;
          case trialActionsString()[4]: // Request for Warrant of Removal - Breach of Consent - Vacate without Payment Plan
            this.$router.push({ name: "wor-trial-action" });
            break;
        }
      } else {
        this.$router.push({ name: "wor-payment-plan" });
      }
    },
    changeDueDate(item) {
      const index = this.consentTermRents.indexOf(item);
      this.consentTermRents[index].menu = false;
      this.consentTermRents[index].dueDate = this.$dayjs(
        item.selectedDueDate
      ).format("MM/DD/YYYY");
      const selectedDay = this.$dayjs(item.selectedDueDate).format("DD");
      if (parseInt(selectedDay) !== parseInt(this.currentCase.rentDueBy)) {
        this.consentTermRents[index].changed = true;
      }
    },
  },
};
</script>

<style lang="scss">
.payments-form {
  &__form {
    // width: 70%;

    &-add-action {
      margin-top: -20px;
    }

    &-divider {
      margin: 24px auto;
    }
  }
}
</style>
