<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$router.push({ name: 'case-view-overview' })"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div style="width: 80%" class="pt-15 pb-4 mx-auto text-left">
      <div class="custom-title pb-4">Post Trial Actions</div>
      <div class="pb-4 mb-8">
        Now that trial is behind you, let us know what document you need to
        draft next so that we may better help you with the next steps.
      </div>
      <div
        class="py-4"
        style="
          height: 370px;
          border: 1px solid #c3d1d2;
          border-radius: 20px;
          position: relative;
        "
      >
        <vue-scroll :ops="ops">
          <v-menu
            v-show="!loading"
            v-for="key in keys"
            :key="key"
            :close-on-content-click="key === 'Other' ? false : true"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-group v-model="selectedKey">
                <v-list-item
                  v-bind="attrs"
                  v-on="on"
                  class="py-1 secondary--text list"
                  :class="key === selectedKey ? 'font-weight-medium' : ''"
                  :value="key"
                >
                  <div>
                    {{
                      key === selectedTrialAction.split("-")[0].trim()
                        ? selectedTrialAction
                        : key
                    }}
                  </div>
                  <v-icon
                    v-if="trialActionsObject[key].length"
                    class="ml-auto"
                    color="accent"
                    size="xx-large"
                  >
                    mdi-menu-right
                  </v-icon>
                </v-list-item>
              </v-list-item-group>
            </template>

            <v-list
              v-if="trialActionsObject[key].length"
              class="secondary--text"
            >
              <v-radio-group v-model="selectedTrialAction">
                <v-list-item
                  class="secondary--text list"
                  v-for="value in trialActionsObject[key]"
                  :key="value"
                >
                  <v-radio :label="value" :value="key + ' - ' + value" />
                </v-list-item>
              </v-radio-group>
            </v-list>

            <v-card v-if="key === 'Other' && key === selectedKey">
              <div class="pl-4 pt-4">Input Other Outcome</div>
              <v-text-field
                v-model="otherText"
                class="mt-0 ml-4 mr-16 py-2"
                single-line
                type="text"
                @keydown.enter="saveOtherText"
              />
            </v-card>
          </v-menu>
        </vue-scroll>
      </div>
    </div>

    <v-dialog v-model="complicateDialogOpen" max-width="600">
      <ComplicateDialog @endFlow="cancel" />
    </v-dialog>

    <v-dialog v-model="upcomingFeatureDialogOpen" max-width="600">
      <UpcomingFeatureDialog
        @close:dialog="upcomingFeatureDialogOpen = false"
      />
    </v-dialog>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="cancel"
      >
        Cancel
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        :disabled="!selectedTrialAction"
        @click="next"
      >
        Continue
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import {
  trialActionsObject,
  trialActionsString,
  breachTermsString,
} from "@/utils/worFlowData";
import ComplicateDialog from "@/components/dialogs/ComplicateDialog";
import UpcomingFeatureDialog from "@/components/dialogs/UpcomingFeatureDialog";
import { mapGetters } from "vuex";

export default {
  name: "TrialAction",
  components: {
    ComplicateDialog,
    UpcomingFeatureDialog,
  },
  data() {
    return {
      trialActionsObject,
      selectedKey: "",
      selectedTrialAction: "",
      otherText: "",
      menu: false,
      loading: false,
      complicateDialogOpen: false,
      upcomingFeatureDialogOpen: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          opacity: 0,
          size: "8px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "8px",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
      caseSteps: "eviction/caseSteps",
    }),
    keys() {
      return Object.keys(this.trialActionsObject);
    },
  },
  watch: {
    selectedKey(key) {
      if (!key) return;
      if (!this.trialActionsObject[key].length) {
        this.selectedTrialAction = key;
        if (key === "Other")
          this.selectedTrialAction = `${key} - ${this.otherText}`;
      }
    },
    otherText(val) {
      this.selectedTrialAction = `${this.selectedKey} - ${val}`;
    },
  },
  activated() {
    window.scrollTo(0, 0);
    if (this.currentCase.trialAction) {
      this.selectedTrialAction = this.currentCase.trialAction;
      this.selectedKey = this.selectedTrialAction.split("-")[0].trim();
    }
  },
  methods: {
    saveOtherText(evt) {
      this.otherText = evt.target.value;
      this.menu = false;
    },
    async saveData() {
      const trialActionResult = {
        trialAction: this.selectedTrialAction,
      };
      if (this.selectedTrialAction !== this.currentCase.trialAction) {
        switch (this.selectedTrialAction) {
          case trialActionsString()[2]: // Request for Warrant of Removal - Breach of Consent - Pay and Stay
            trialActionResult.breachTerms = [
              { desc: breachTermsString[1], selected: false },
              { desc: breachTermsString[2], selected: false },
            ];
            break;
          case trialActionsString()[4]: // Request for Warrant of Removal - Breach of Consent - Vacate without Payment Plan
            trialActionResult.breachTerms = [
              { desc: breachTermsString[0], selected: false },
              // { desc: breachTermsString[2], selected: false },
            ];
            break;
          default:
            trialActionResult.breachTerms = [
              { desc: breachTermsString[0], selected: false },
              { desc: breachTermsString[1], selected: false },
              { desc: breachTermsString[2], selected: false },
            ];
            trialActionResult.breachPayments = [];
            trialActionResult.breachOtherConds = [];
        }
        await this.$store.dispatch("eviction/updateCase", {
          caseData: trialActionResult,
          status: "wor-trialAction",
        });
      }
    },
    async next() {
      await this.saveData();
      const steps = this.caseSteps;
      switch (this.selectedTrialAction) {
        case trialActionsString()[0]: // Request for Warrant of Removal - Default Judgment
        case trialActionsString()[1]: // Request for Warrant of Removal - Judgment for Possession
          steps.trialOutcome.children = {
            consentLedger: {
              label: "Rent Ledger",
              status: "",
              routeName: "wor-trial-rent-ledger",
            },
            confirmDates: {
              label: "Confirm Dates",
              status: "",
              routeName: "wor-trial-confirm-dates",
            },
            signature: {
              label: "Let's Finalize",
              status: "",
              routeName: "wor-trial-signature",
            },
          };
          this.$store.commit("eviction/update_steps", steps);
          this.$router.push({ name: "wor-trial-rent-ledger" });
          break;
        case trialActionsString()[2]: // Request for Warrant of Removal - Breach of Consent - Pay and Stay
          steps.trialOutcome.children = {
            paymentPlan: {
              label: "Payment Plan",
              status: "",
              routeName: "wor-trial-payment-plan",
            },
            rentConsentTerm: {
              label: "Rent During the Consent Term",
              status: "",
              routeName: "wor-trial-rent-consent-term",
            },
            confirmBreach: {
              label: "Confirmation of Breach",
              status: "",
              routeName: "wor-trial-confirm-breach",
            },
            consentLedger: {
              label: "Rent Ledger",
              status: "",
              routeName: "wor-trial-consent-ledger",
            },
            confirmDates: {
              label: "Confirm Dates",
              status: "",
              routeName: "wor-trial-confirm-dates",
            },
            signature: {
              label: "Let's Finalize",
              status: "",
              routeName: "wor-trial-signature",
            },
          };
          this.$store.commit("eviction/update_steps", steps);
          this.$router.push({ name: "wor-trial-payment-plan" });
          break;
        case trialActionsString()[3]: // Request for Warrant of Removal - Breach of Consent - Vacate with Payment Plan
          steps.trialOutcome.children = {
            paymentPlan: {
              label: "Payment Plan",
              status: "",
              routeName: "wor-trial-payment-plan",
            },
            confirmBreach: {
              label: "Confirmation of Breach",
              status: "",
              routeName: "wor-trial-confirm-breach",
            },
            consentLedger: {
              label: "Rent Ledger",
              status: "",
              routeName: "wor-trial-consent-ledger",
            },
            confirmDates: {
              label: "Confirm Dates",
              status: "",
              routeName: "wor-trial-confirm-dates",
            },
            signature: {
              label: "Let's Finalize",
              status: "",
              routeName: "wor-trial-signature",
            },
          };
          this.$store.commit("eviction/update_steps", steps);
          this.$router.push({ name: "wor-trial-payment-plan" });
          break;
        case trialActionsString()[4]: // Request for Warrant of Removal - Breach of Consent - Vacate without Payment Plan
          steps.trialOutcome.children = {
            rentConsentTerm: {
              label: "Rent During the Consent Term",
              status: "",
              routeName: "wor-trial-rent-consent-term",
            },
            confirmBreach: {
              label: "Confirmation of Breach",
              status: "",
              routeName: "wor-trial-confirm-breach",
            },
            consentLedger: {
              label: "Rent Ledger",
              status: "",
              routeName: "wor-trial-consent-ledger",
            },
            confirmDates: {
              label: "Confirm Dates",
              status: "",
              routeName: "wor-trial-confirm-dates",
            },
            signature: {
              label: "Let's Finalize",
              status: "",
              routeName: "wor-trial-signature",
            },
          };
          this.$store.commit("eviction/update_steps", steps);
          this.$router.push({ name: "wor-trial-rent-consent-term" });
          break;
        case trialActionsString()[5]: // Executed Consent to Enter Judgment - Tenant Pays and Stays
          steps.trialOutcome.children = {
            filedConsentJudgment: {
              label: "Filed Consent To Enter Judgment",
              status: "",
              routeName: "wor-trial-filed-consent-judgment",
            },
            paymentPlan: {
              label: "Payment Plan",
              status: "",
              routeName: "wor-trial-payment-plan",
            },
            rentConsentTerm: {
              label: "Rent During the Consent Term",
              status: "",
              routeName: "wor-trial-rent-consent-term",
            },
            additionalConditions: {
              label: "Additional Conditions",
              status: "",
              routeName: "wor-trial-additional-condition",
            },
            signature: {
              label: "Let's Finalize",
              status: "",
              routeName: "wor-trial-signature",
            },
          };
          this.$store.commit("eviction/update_steps", steps);
          this.$router.push({ name: "wor-trial-filed-consent-judgment" });
          break;
        case trialActionsString()[6]: // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
          steps.trialOutcome.children = {
            filedConsentJudgment: {
              label: "Filed Consent To Enter Judgment",
              status: "",
              routeName: "wor-trial-filed-consent-judgment",
            },
            moveOutDate: {
              label: "Move Out Date",
              status: "",
              routeName: "wor-trial-move-out-date",
            },
            paymentPlan: {
              label: "Payment Plan",
              status: "",
              routeName: "wor-trial-payment-plan",
            },
            additionalConditions: {
              label: "Additional Conditions",
              status: "",
              routeName: "wor-trial-additional-condition",
            },
            signature: {
              label: "Let's Finalize",
              status: "",
              routeName: "wor-trial-signature",
            },
          };
          this.$store.commit("eviction/update_steps", steps);
          this.$router.push({ name: "wor-trial-filed-consent-judgment" });
          break;
        case trialActionsString()[7]: // Executed Consent to Enter Judgment - Tenant Vacates without Payment Plan
          steps.trialOutcome.children = {
            filedConsentJudgment: {
              label: "Filed Consent To Enter Judgment",
              status: "",
              routeName: "wor-trial-filed-consent-judgment",
            },
            moveOutDate: {
              label: "Move Out Date",
              status: "",
              routeName: "wor-trial-move-out-date",
            },
            signature: {
              label: "Let's Finalize",
              status: "",
              routeName: "wor-trial-signature",
            },
          };
          this.$store.commit("eviction/update_steps", steps);
          this.$router.push({ name: "wor-trial-filed-consent-judgment" });
          break;
        case trialActionsString()[8]: // Judgments - Default Judgment
        case trialActionsString()[9]: // Judgments - Judgment for Possession
        case trialActionsString()[10]: // Judgments - Judgment after Breach
          this.upcomingFeatureDialogOpen = true;
          break;
        default:
          this.complicateDialogOpen = true;
          break;
      }
    },
    cancel() {
      this.$router.push({ name: "case-view-overview" });
    },
  },
};
</script>

<style scoped>
.v-menu__content:empty {
  box-shadow: none;
}
.v-menu__content {
  min-width: 400px !important;
}
.v-input {
  font-size: 20px;
}
</style>
