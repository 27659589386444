<template>
  <svg viewBox="0 0 32 34.293">
    <!-- viewBox="0 0 32 34.293" -->
    <path
      class="a svg-icon"
      d="M123.079,77.065H103.935a5.539,5.539,0,0,0-9.8,0H92.513a.711.711,0,0,0-.713.713v3.735a.711.711,0,0,0,.713.713h1.623a5.542,5.542,0,0,0,9.8,0h19.147a.711.711,0,0,0,.713-.713V77.779A.717.717,0,0,0,123.079,77.065Zm-22.906,5.166a2.822,2.822,0,1,1-1.133-5.405,2.858,2.858,0,0,1,1.141.239,2.818,2.818,0,0,1,1.685,2.583A2.849,2.849,0,0,1,100.172,82.232Zm22.906-16.776h-5.57a5.536,5.536,0,0,0-9.8,0h-15.2a.711.711,0,0,0-.713.713V69.9a.711.711,0,0,0,.713.713h15.2a5.542,5.542,0,0,0,9.8,0h5.57a.711.711,0,0,0,.713-.713V66.169A.717.717,0,0,0,123.079,65.455Zm-9.333,5.162a2.823,2.823,0,1,1-1.137-5.405,2.821,2.821,0,0,1,1.137,5.405Zm9.333,18.062h-9.145a5.539,5.539,0,0,0-9.8,0H92.513a.711.711,0,0,0-.713.713v3.735a.711.711,0,0,0,.713.713h11.63a5.542,5.542,0,0,0,9.8,0h9.145a.711.711,0,0,0,.713-.713V89.393A.723.723,0,0,0,123.079,88.679Zm-12.907,5.166a2.822,2.822,0,1,1-1.133-5.405,2.858,2.858,0,0,1,1.141.239,2.819,2.819,0,0,1-.008,5.166Z"
      transform="translate(-91.8 -62.5)"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
