<template>
  <div class="py-4 px-12 mx-4">
    <div class="d-flex align-center justify-space-between my-8">
      <div class="d-flex flex-row align-center secondary--text">
        <v-icon color="primary" size="xxx-large" @click="$router.push('/')"
          >mdi-arrow-left-drop-circle</v-icon
        >
        <span class="pl-8 font-weight-medium" style="font-size: 40px">
          Case ID:
          <span style="text-transform: uppercase">{{
            currentCase.attorneyInfo != null
              ? `${currentCase.attorneyInfo.firstName}${currentCase.attorneyInfo.lastName}`.slice(
                  0,
                  3
                ) +
                "-" +
                `${currentCase.clientInfo.firstName}${currentCase.clientInfo.lastName}`.slice(
                  0,
                  3
                ) +
                "-" +
                currentCase.id
              : ""
          }}</span>
        </span>
      </div>
      <div class="d-flex flex-row align-center secondary--text">
        <span class="ml-4" style="font-size: 30px">
          Client:
          {{
            currentCase.clientInfo != null
              ? `${currentCase.clientInfo.firstName} ${currentCase.clientInfo.lastName}`
              : ""
          }}
        </span>
      </div>
    </div>

    <SubTopNav :value="1" />

    <div class="mx-16 mb-16 px-16">
      <v-row class="mt-16">
        <v-container
          v-for="(item, idx) in checkLists"
          :key="item.name"
          fluid
          class="pa-0 mb-4 secondary--text"
        >
          <v-row no-gutters justify="center" align="center">
            <v-col cols="12">
              <v-container>
                <v-row>
                  <div
                    class="suit-amount-summary-item elevated-bar align-center d-flex"
                    @click="openClose(item.name)"
                  >
                    <v-col cols="10">
                      <div class="text-left pl-8">
                        {{ item.name }}
                      </div>
                    </v-col>
                    <v-col
                      style="height: 100%"
                      cols="2"
                      class="pa-0 d-flex justify-end"
                    >
                      <div
                        class="elevated-bar-btn d-flex align-center justify-center"
                      >
                        <v-icon style="font-size: 75px; color: #93aebf">
                          mdi-menu-{{ direction(item.name) }}
                        </v-icon>
                      </div>
                    </v-col>
                  </div>
                </v-row>
                <v-card
                  class="mt-6 mb-4 pt-12 pb-8 secondary--text"
                  style="
                    box-shadow: 15px 15px 40px #00000029;
                    border-radius: 20px;
                  "
                  v-if="item.name === open"
                >
                  <v-row
                    class="d-flex justify-space-between mx-16"
                    v-for="(row, index) in item.checks"
                    :key="index"
                  >
                    <div class="mb-2" style="font-size: 20px">
                      <Checkbox
                        v-if="row.title"
                        v-model="row.checked"
                        :label="row.title"
                      />
                      <v-text-field
                        v-else
                        v-model="tmpTitle"
                        @keydown.enter="syncTitle(idx, index)"
                      />
                    </div>
                    <v-icon v-if="idx != 2" size="xxx-large" color="primary"
                      >icofont-cloud-upload</v-icon
                    >
                  </v-row>
                  <v-divider class="mt-4 mb-8 mx-10" />
                  <v-row class="d-flex align-center ml-10">
                    <v-btn
                      class="btn--plain"
                      color="accent"
                      icon
                      tile
                      @click="addDocument(idx)"
                    >
                      <v-icon style="font-size: 35px"
                        >mdi-plus-circle-outline</v-icon
                      >
                    </v-btn>
                    <div
                      class="ml-3 info--text caption text-uppercase font-weight-medium"
                      style="font-size: 20px !important"
                    >
                      ADD DOCUMENT OR TASK
                    </div>
                  </v-row>
                  <v-row
                    v-if="idx != 1"
                    class="d-flex align-center justify-space-between mx-16"
                  >
                    <Checkbox
                      v-model="item.clientSignature"
                      label="CLIENT SIGNAGURE"
                    />
                    <div
                      class="d-flex flex-column signature-pad"
                      style="height: 100px; padding: 20px 40px"
                    >
                      <span class="text-uppercase pl-4">{{
                        item.clientSignature
                          ? `${currentCase.clientInfo.firstName} ${currentCase.clientInfo.lastName}`
                          : ""
                      }}</span>
                      <span
                        class="font-weight-medium"
                        :class="item.clientSignature ? '' : 'mt-8'"
                        style="text-transform: capitalize"
                        >{{
                          `${currentCase.clientInfo.firstName} ${currentCase.clientInfo.lastName}, Project Manager`
                        }}</span
                      >
                    </div>
                  </v-row>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
      <v-row class="mt-16">
        <v-col>
          <case-notes-card />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <DocListCard title="Uploads" :documents="currentCase.documents" />
        </v-col>
        <v-col>
          <DocListCard
            title="Pleadings"
            :documents="pleadingDocuments"
            id="test"
          />
        </v-col>
      </v-row>
      <v-row> </v-row>
    </div>
  </div>
</template>

<script>
import DocListCard from "../DocListCard";
import CaseNotesCard from "../CaseNotesCard";
import Checkbox from "@/components/Checkbox.vue";
import SubTopNav from "@/components/SubTopNav.vue";
import { mapGetters } from "vuex";

export default {
  name: "MatterManagement",
  components: {
    DocListCard,
    CaseNotesCard,
    Checkbox,
    SubTopNav,
  },
  data() {
    return {
      open: "",
      checkLists: [
        {
          name: "Verified Complaint",
          checks: [
            {
              title: "RENT LEDGER",
              checked: false,
            },
            {
              title: "COPIES OF CHECKS",
              checked: false,
            },
            {
              title: "PHOTO",
              checked: false,
            },
            {
              title: "CORRESPONDENCE",
              checked: false,
            },
            {
              title: "LEASE",
              checked: false,
            },
          ],
          clientSignature: false,
        },
        {
          name: "Certification of Lease and Registration",
          checks: [
            {
              title: "LEASE",
              checked: false,
            },
            {
              title: "LANDLORD REGISTRATION",
              checked: false,
            },
          ],
        },
        {
          name: "Request for Warrant of Removal",
          checks: [
            {
              title: "UPDATE AND CONFIRM RENT BALANCE",
              checked: false,
            },
          ],
          clientSignature: false,
        },
      ],
      tmpTitle: "",
      prevRoute: { name: "" },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
      pleadingDocuments: "eviction/pleadings",
    }),
    direction() {
      return (name) => (name === this.open ? "up" : "down");
    },
  },
  async mounted() {
    if (this.prevRoute.name.includes("signature")) window.scrollTo(0, 1020);
    await this.$store.dispatch("eviction/loadPleadings");
    await this.$store.dispatch("eviction/loadDocuments", {
      caseId: this.currentCase.id,
      name: "",
    });
  },
  methods: {
    openClose(name) {
      if (this.open === name) {
        this.open = "";
      } else {
        this.open = name;
      }
    },
    addDocument(idx) {
      this.tmpTitle = "";
      this.checkLists[idx].checks.push({
        title: "",
        checked: false,
      });
    },
    syncTitle(idx, index) {
      // idx: index of item in checklists, index: index of item in sub checklists
      this.checkLists[idx].checks[index].title = this.tmpTitle;
    },
  },
};
</script>

<style>
.rounded-card.v-card.v-sheet.theme--light {
  border-radius: 20px;
}
.active-bottom-btn {
  color: white !important;
  background-color: #4dccc4 !important;
}
</style>
