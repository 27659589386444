import { render, staticRenderFns } from "./NewEventDialog.vue?vue&type=template&id=35c9201a&scoped=true&"
import script from "./NewEventDialog.vue?vue&type=script&lang=js&"
export * from "./NewEventDialog.vue?vue&type=script&lang=js&"
import style0 from "./NewEventDialog.vue?vue&type=style&index=0&id=35c9201a&prod&lang=css&"
import style1 from "./NewEventDialog.vue?vue&type=style&index=1&id=35c9201a&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c9201a",
  null
  
)

export default component.exports