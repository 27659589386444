var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"attorneys-table"},[(_vm.attorneys.length > 0)?_c('v-data-table',{staticClass:"my-table",attrs:{"headers":_vm.headers,"header-props":{ 'sort-icon': 'mdi-menu-up' },"must-sort":true,"items":_vm.attorneys,"search":_vm.search,"custom-filter":_vm.customFilter,"item-key":"ID","items-per-page":_vm.itemsPerPage,"footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-skip-previous',
      lastIcon: 'mdi-skip-next',
      prevIcon: 'mdi-menu-left',
      nextIcon: 'mdi-menu-right',
      itemsPerPageText: 'Number of attorneys to display',
      pageText: _vm.pageText,
    }},on:{"page-count":function($event){_vm.pageCount = $event},"update:options":_vm.updateOptions},scopedSlots:_vm._u([{key:"item",fn:function({ item, index }){return [_c('tr',{staticClass:"data-row",class:{ 'on-hover': _vm.rowHoverIndex === index },on:{"mouseover":function($event){_vm.rowHoverIndex = index},"mouseleave":function($event){_vm.rowHoverIndex = -1}}},[_c('td',{staticClass:"first-column"},[_c('div',{staticClass:"d-flex flex-row align-center"},[(item.avatar)?_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":item.avatar}})],1):_c('v-avatar',{staticClass:"white--text",attrs:{"size":"40","color":_vm.avatarBackgroundColor(`${item.firstName} ${item.lastName}`)}},[_vm._v(" "+_vm._s(item.firstName.slice(0, 1).toUpperCase() + item.lastName.slice(0, 1).toUpperCase())+" ")]),_c('span',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"pl-4 font-weight-medium"},[_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]),_c('span',{staticClass:"pl-4"},[_vm._v(" "+_vm._s(item.company)+" ")])])],1)]),_c('td',[_vm._v(" "+_vm._s(_vm.$dayjs(item.retained_at).format("MM/DD/YYYY"))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.lastActivity ? _vm.$dayjs(item.lastActivity).format("MM/DD/YYYY") : "No activity")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.cases)+" ")]),_c('td',{staticClass:"py-2"},[_c('tr',[_vm._v(" Email: "+_vm._s(item.email)+" ")]),_c('tr',[_vm._v(" Mobile Phone: "+_vm._s(item.phone)+" ")]),_c('tr',[_vm._v(" Home/Office Phone: "+_vm._s(item.cell)+" "+_vm._s(item.ext)+" ")])])]),_c('td',{staticClass:"row-divider",attrs:{"colspan":_vm.headers.length}})]}}],null,false,3296555416),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[(_vm.attorneys.length !== 0)?_c('template',{slot:"body.append"},[_c('tr',{staticStyle:{"height":"30px"}})]):_vm._e()],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }