<template>
  <svg viewBox="0 0 30 28.104">
    <path
      class="a svg-icon"
      d="M76.905,111.582a.861.861,0,0,0,1.2,0l10.088-9.8a.808.808,0,0,0,0-1.162l-2.006-1.948a.861.861,0,0,0-1.2,0l-4.773,4.636V90.823A.835.835,0,0,0,79.367,90H75.986a.835.835,0,0,0-.847.823v12.828l-5.123-4.979a.861.861,0,0,0-1.2,0l-2.006,1.948a.808.808,0,0,0,0,1.162Zm11.432-4.725v7.081H66.663v-7.081H62.5v9.166a2.079,2.079,0,0,0,2.085,2.081h25.83a2.081,2.081,0,0,0,2.085-2.081v-9.166Z"
      transform="translate(-62.5 -90)"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
