<template>
  <StandardDialog
    title="Add Payments"
    cancelBtnText="go back"
    saveBtnText="add"
    :cancelFunction="close"
    :saveFunction="complete"
    :formValid="formValid"
    contentWidth="70%"
  >
    <div class="utilities-dialog">
      <v-form
        ref="formRange"
        class="utilities-dialog__form px-8 pt-10 pb-4 mx-auto text-left"
      >
        <div class="font-weight-medium secondary--text pb-2">Details</div>
        <v-select
          v-model="singlePayment.details"
          rounded
          solo
          flat
          dense
          background-color="#F0F5F6"
          :items="['Payment', 'Forgiveness', 'Write-Off']"
        >
          <v-icon slot="append" medium color="accent">
            mdi-chevron-down
          </v-icon>
        </v-select>

        <div class="font-weight-medium secondary--text pb-2">Amount paid</div>
        <v-text-field
          v-model="singlePayment.amount"
          rounded
          type="number"
          solo
          flat
          dense
          background-color="#F0F5F6"
        >
          <div slot="prepend-inner">$</div>
        </v-text-field>

        <div class="font-weight-medium secondary--text pb-2">Date paid</div>
        <v-menu
          v-model="singlePayment.menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="singlePayment.date"
              append-icon="$calendar"
              color="accent"
              readonly
              rounded
              solo
              flat
              dense
              background-color="#F0F5F6"
              v-on="on"
              @click:append="singlePayment.menu = true"
            />
          </template>
          <v-date-picker
            v-model="singlePayment.selectedDate"
            no-title
            :show-current="false"
            color="primary"
            class="custom-date"
            @input="fillDateInSinglePayment"
          />
        </v-menu>

        <div
          v-if="!areThereAnyAdditionalForm"
          class="utilities-dialog__form-add-action d-flex align-center mt-1 mr-4"
        >
          <v-btn
            class="btn--plain"
            color="accent"
            icon
            tile
            :disabled="!formValid"
            @click="addAdditionalForm"
          >
            <v-icon style="font-size: 28px">mdi-plus-circle-outline</v-icon>
          </v-btn>
          <div class="ml-3 info--text caption text-uppercase">
            ADD ADDITIONAL PAYMENT
          </div>
        </div>
      </v-form>

      <v-divider
        v-if="areThereAnyAdditionalForm"
        width="80%"
        class="utilities-dialog__form-divider"
      />

      <v-form
        v-if="areThereAnyAdditionalForm"
        class="utilities-dialog__form px-8 pt-10 pb-4 mx-auto text-left"
      >
        <div v-for="(payment, index) in additionalPayments" :key="index">
          <div class="font-weight-medium secondary--text pb-2">Details</div>
          <v-select
            v-model="payment.details"
            rounded
            solo
            flat
            dense
            background-color="#F0F5F6"
            :items="['Payment', 'Forgiveness', 'Write-Off']"
          >
            <v-icon slot="append" medium color="accent">
              mdi-chevron-down
            </v-icon>
          </v-select>

          <div class="font-weight-medium secondary--text pb-2">Amount paid</div>
          <v-text-field
            v-model="payment.amount"
            rounded
            type="number"
            solo
            flat
            dense
            background-color="#F0F5F6"
          >
            <div slot="prepend-inner">$</div>
          </v-text-field>

          <div class="font-weight-medium secondary--text pb-2">Date paid</div>
          <v-menu
            v-model="payment.menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="payment.date"
                append-icon="$calendar"
                color="accent"
                readonly
                rounded
                solo
                flat
                dense
                background-color="#F0F5F6"
                v-on="on"
                @click:append="payment.menu = true"
                :rules="[(v) => !!v || 'Item is required']"
              />
            </template>
            <v-date-picker
              v-model="payment.selectedDate"
              no-title
              :show-current="false"
              color="primary"
              class="custom-date"
              @input="(data) => fillDateInAdditionalPayments(data, index)"
            />
          </v-menu>

          <div class="d-flex justify-end">
            <span
              class="body-2"
              style="color: #fa8b5c; cursor: pointer"
              @click="deletePayment(index)"
            >
              Delete
            </span>
          </div>
        </div>

        <div class="utilities-dialog__form-add-action d-flex align-center mr-4">
          <v-btn
            class="btn--plain"
            color="accent"
            icon
            tile
            :disabled="!formValid"
            @click="addAdditionalForm"
          >
            <v-icon style="font-size: 28px"> mdi-plus-circle-outline </v-icon>
          </v-btn>
          <div class="ml-3 info--text caption text-uppercase">
            ADD ADDITIONAL PAYMENT
          </div>
        </div>
      </v-form>
    </div>
  </StandardDialog>
</template>

<script>
import StandardDialog from "./StandardDialog.vue";
import { mapGetters } from "vuex";

export default {
  name: "AddPayDialog",
  components: {
    StandardDialog,
  },
  props: {
    suitAmount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      singlePayment: {
        details: "",
        amount: "",
        date: "",
        selectedDate: "",
        menu: false,
        selected: true,
      },
      additionalPayments: [],
    };
  },
  computed: {
    ...mapGetters({
      case: "eviction/currentCase",
    }),
    areThereAnyAdditionalForm() {
      return this.additionalPayments.length > 0;
    },
    formValid() {
      if (this.additionalPayments.length > 0) {
        let valid = true;
        this.additionalPayments.forEach((item) => {
          if (!item.amount || !item.date) valid = false;
        });
        if (!this.singlePayment.amount || !this.singlePayment.date)
          valid = false;
        return valid;
      } else {
        return this.singlePayment.amount && this.singlePayment.date;
      }
    },
  },
  methods: {
    initialization() {
      this.singlePayment = {
        details: "",
        amount: null,
        date: "",
        selectedDate: "",
        menu: false,
        selected: true,
      };
      this.additionalPayments = [];
    },
    close() {
      this.$emit("close:dialog");
      this.initialization();
    },
    addAdditionalForm() {
      this.additionalPayments.push({
        details: "",
        amount: null,
        date: "",
        selectedDate: "",
        menu: false,
        selected: true,
      });
    },
    complete() {
      const data = [this.singlePayment, ...this.additionalPayments];
      const newPayments = data.reduce((result, item) => {
        if (item.amount && item.date) {
          result = [
            ...result,
            {
              date: this.$dayjs(item.selectedDate).format("MM/DD/YYYY"),
              dateForSort: this.$dayjs(item.date).format("YYYY-MM-DD"),
              details: item.details,
              amount: 0,
              payment: item.amount,
              totalBalance: 0,
            },
          ];
        }
        return result;
      }, []);
      this.$emit("add", newPayments);
      this.close();
    },
    deletePayment(index) {
      this.$delete(this.additionalPayments, index);
    },
    fillDateInSinglePayment(data) {
      this.singlePayment.selectedDate = data;
      this.singlePayment.date = this.$dayjs(data).format("MMMM DD, YYYY");
      this.singlePayment.menu = false;
    },
    fillDateInAdditionalPayments(data, index) {
      this.additionalPayments[index].selectedDate = data;
      this.additionalPayments[index].date =
        this.$dayjs(data).format("MMMM DD, YYYY");
      this.additionalPayments[index].menu = false;
    },
  },
};
</script>

<style lang="scss">
.utilities-dialog {
  &__form {
    // width: 70%;

    &-add-action {
      margin-top: -20px;
    }

    &-divider {
      margin: 24px auto;
    }
  }
}
</style>
