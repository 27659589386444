<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div style="width: 80%" class="pt-15 pb-4 mx-auto text-left">
      <div class="custom-title pb-4">Move Out Date</div>
      <div class="pb-8 mb-8">
        On what date do the parties agree the tenant(s) would vacate the
        property? This date will appear on the Consent to Enter Judgment -
        Tenant Vacates.
      </div>
      <v-row justify="center" class="pb-8">
        <v-date-picker
          v-model="moveOutDate"
          no-title
          color="primary"
          class="py-4 px-6"
          style="box-shadow: 15px 15px 40px #00000029"
        />
      </v-row>
    </div>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="goBack"
      >
        Go Back
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        :disabled="!moveOutDate"
        @click="next"
      >
        Continue
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { courtOutcomesString, trialActionsString } from "@/utils/worFlowData";

export default {
  name: "MoveOutDate",
  data() {
    return {
      moveOutDate: null,
    };
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
  },
  activated() {
    window.scrollTo(0, 0);
    this.moveOutDate = this.currentCase.moveOutDate ?? null;
  },
  deactivated() {
    this.saveData();
  },
  methods: {
    async saveData() {
      await this.$store.dispatch("eviction/updateCase", {
        caseData: {
          moveOutDate: this.moveOutDate,
        },
        status: this.$route.name.includes("trial")
          ? "wor-moveOutDate"
          : "co-moveOutDate",
      });
    },
    next() {
      if (this.$route.name.includes("trial")) {
        // Post Trial Actions flow
        switch (this.currentCase.trialAction) {
          case trialActionsString()[3]: // Request for Warrant of Removal - Breach of Consent - Vacate with Payment Plan
            if (this.currentCase.breachTerms[1].selected)
              this.$router.push({ name: "wor-trial-breach-payment-plan" });
            else if (this.currentCase.breachTerms[2].selected)
              this.$router.push({ name: "wor-trial-breach-other-conditions" });
            else this.$router.push({ name: "wor-trial-consent-ledger" });
            break;
          case trialActionsString()[4]: // Request for Warrant of Removal - Breach of Consent - Vacate without Payment Plan
            this.$router.push({ name: "wor-trial-consent-ledger" });
            break;
          case trialActionsString()[6]: // Executed Consent to Enter Judgment - Tenant Vacates with Payment Plan
            this.$router.push({ name: "wor-trial-payment-plan" });
            break;
          case trialActionsString()[7]: // Executed Consent to Enter Judgment - Tenant Vacates without Payment Plan
            this.$router.push({ name: "wor-trial-signature" });
            break;
        }
      } else {
        // court outcome flow
        if (this.currentCase.courtOutcome === courtOutcomesString()[4]) {
          // Executed Consent to Enter Judgment - Tenant Vacates without Payment Plan
          this.$router.push({ name: "wor-additional-condition" });
        } else {
          this.$router.push({ name: "wor-payment-plan" });
        }
      }
    },
    goBack() {
      if (this.$route.name.includes("trial")) {
        // Post Trial Actions flow
        switch (this.currentCase.trialAction) {
          case trialActionsString()[3]: // Request for Warrant of Removal - Breach of Consent - Vacate with Payment Plan
          case trialActionsString()[4]: // Request for Warrant of Removal - Breach of Consent - Vacate without Payment Plan
            this.$router.push({ name: "wor-trial-confirm-breach" });
            break;
          default:
            this.$router.push({ name: "wor-trial-action" });
        }
      } else {
        this.$router.push({ name: "wor-court-outcome" });
      }
    },
  },
};
</script>

<style>
.v-date-picker-table {
  height: 252px;
}
</style>
