<template>
  <div class="mx-auto secondary--text">
    <v-btn
      color="info"
      icon
      class="float-right ma-8 btn--plain"
      @click="$emit('close:form')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>

    <div style="width: 80%" class="pt-15 pb-4 mx-auto text-left">
      <div class="custom-title pt-8 pb-4">
        Ledger Post Consent to Enter Judgment
      </div>
      <div class="pb-4">
        The below reflects the terms of the Payment Plan, as well as the rental
        amounts that are due and owing during the duration of the Payment Plan
        term. Please update this ledger with any payments made towards the
        payment plan / rental amounts.
      </div>

      <!-- Rent Ledger Start -->
      <div class="mt-2 mb-4">
        <v-row>
          <v-col class="d-flex flex-column font-weight-bold">
            <span class="mr-4"
              >Consent Drafted Date:
              {{
                $dayjs(currentCase.vcDateConsentDrafted).format("MM/DD/YYYY")
              }}</span
            >
            <span class="mr-4">Last Breach Date: {{ lastBreachDate }}</span>
          </v-col>
        </v-row>
      </div>
      <div class="ledger-table" style="max-height: 420px; overflow: auto">
        <vue-scroll :ops="ops">
          <v-data-table
            :headers="headers"
            :items="ledgerData"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            disable-sort
          >
            <template v-slot:item.amount="props">
              <div class="d-flex">
                <div>
                  {{
                    !props.item.amount
                      ? ""
                      : convertToCurrency(props.item.amount)
                  }}
                </div>
              </div>
            </template>
            <template v-slot:item.payment="props">
              <div class="d-flex">
                <v-edit-dialog
                  v-if="props.item.payment"
                  large
                  persistent
                  @save="savePayAmount()"
                >
                  <div>
                    {{ convertToCurrency(0 - parseFloat(props.item.payment)) }}
                    <v-icon size="14" color="#4dccc4" class="ml-1">
                      $pencil
                    </v-icon>
                  </div>
                  <template v-slot:input>
                    <div class="mt-4">Change amount</div>
                    <v-text-field
                      v-model="props.item.payment"
                      label="Edit"
                      single-line
                      autofocus
                      type="number"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
                <v-icon
                  v-if="props.item.payment"
                  size="18"
                  color="#4dccc4"
                  class="ml-1 mt-1"
                  @click="clearPayAmount(props.item)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </template>
            <template v-slot:item.totalBalance="props">
              <span>{{ convertToCurrency(props.item.totalBalance) }}</span>
            </template>
          </v-data-table>
        </vue-scroll>
      </div>
      <div class="d-flex align-center mt-3 mb-3">
        <div class="ml-1 info--text text-uppercase">
          <v-btn
            class="btn--plain"
            color="accent"
            icon
            tile
            @click="addPayDialogOpen = true"
          >
            <v-icon style="font-size: 28px"> mdi-plus-circle-outline </v-icon>
          </v-btn>
          Add Payments
        </div>
      </div>

      <h2 class="text-right mt-4">
        Total due: {{ convertToCurrency(totalDue) }}
      </h2>

      <div class="mt-8 mb-4">
        <v-row>
          <v-col cols="3">Total Base Rent</v-col>
          <v-col cols="2">Late Fees</v-col>
          <v-col cols="2">Court Fees</v-col>
          <v-col cols="2">Attorney Fees</v-col>
          <v-col cols="3">Total Balance</v-col>
        </v-row>
        <v-row class="font-weight-bold">
          <v-col cols="3">{{ convertToCurrency(totalBaseRent) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(lateFee) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(courtFee) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(attorneyFee) }}</v-col>
          <v-col cols="3">{{ convertToCurrency(totalDue) }}</v-col>
        </v-row>
      </div>
      <!-- Rent Ledger End -->
    </div>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end py-4 pr-12 mt-12"
    >
      <v-btn
        rounded
        color="accent_light"
        class="mb-2 mt-4 btn--plain capital--btn"
        text
        @click="goBack"
      >
        Go Back
      </v-btn>
      <v-btn
        rounded
        color="accent_light"
        class="px-8 mb-2 mt-4 white--text capital--btn"
        depressed
        @click="next"
      >
        Continue
      </v-btn>
    </v-card-actions>

    <div>
      <v-dialog
        v-model="addPayDialogOpen"
        value="''"
        max-width="1000"
        persistent
      >
        <AddPayDialog
          @close:dialog="addPayDialogOpen = false"
          @add="addPayment"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import AddPayDialog from "@/components/dialogs/AddPayDialog";
import { trialActionsString } from "@/utils/worFlowData";
import { mapGetters } from "vuex";

export default {
  name: "ConsentLedger",
  components: {
    AddPayDialog,
  },
  data() {
    return {
      addPayDialogOpen: false,
      headers: [
        {
          text: "Date",
          align: "start",
          value: "date",
        },
        { text: "Details", value: "details" },
        { text: "Amount Due", value: "amount" },
        { text: "Amount Paid", value: "payment" },
        { text: "Total Balance", value: "totalBalance" },
      ],
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 100,
          easing: "easeInQuad",
        },
        rail: {
          background: "#EAEBEB",
          opacity: 1,
          size: "10px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "10px",
          minSize: 0.1,
        },
      },
      ledgerData: [],
      charges: [],
      payments: [],
      totalBaseRent: 0,
      attorneyFee: 0,
      courtFee: 0,
      lateFee: 0,
      totalDue: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
    lastBreachDate() {
      if (this.currentCase.paymentPlan) {
        const lastConsentPayment =
          this.currentCase.paymentPlan[this.currentCase.paymentPlan.length - 1];
        const result = new Date(
          this.$dayjs(lastConsentPayment.dateDue).format("MM/DD/YYYY")
        );
        result.setDate(result.getDate() + 30);
        return this.$dayjs(result).format("MM/DD/YYYY");
      } else {
        const result = new Date(
          this.$dayjs(this.currentCase.moveOutDate).format("MM/DD/YYYY")
        );
        result.setDate(result.getDate() + 30);
        return this.$dayjs(result).format("MM/DD/YYYY");
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.useNext = false;
    /* add data to table - START */
    this.charges = [];
    let chargeItem;

    const paymentPlan = this.currentCase.paymentPlan || [];
    for (const element of paymentPlan) {
      chargeItem = {};
      chargeItem.dateForSort = this.$dayjs(element.dateDue).format(
        "YYYY-MM-DD"
      );
      chargeItem.date = this.$dayjs(element.dateDue).format("MM/DD/YYYY");
      chargeItem.details = "Consent amount";
      chargeItem.amount = parseFloat(element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
    }

    const consentTermRents =
      this.currentCase.consentTermRents.filter((item) => item.happened) || [];
    for (const element of consentTermRents) {
      chargeItem = {};
      chargeItem.dateForSort = this.$dayjs(element.dueDate).format(
        "YYYY-MM-DD"
      );
      chargeItem.date = this.$dayjs(element.dueDate).format("MM/DD/YYYY");
      chargeItem.details = "Rent";
      chargeItem.amount = parseFloat(!element.amount ? 0 : element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
    }

    this.payments = this.currentCase.consentPayments || [];

    this.applyChangeInCharge();
  },
  deactivated() {
    this.saveData();
  },
  methods: {
    async saveData() {
      if (this.useNext) return;
      await this.$store.dispatch("eviction/updateCase", {
        caseData: {
          consentPayments: this.payments,
          consentTotalBaseRent: this.totalBaseRent,
        },
        status: "wor-consentLedger",
      });
    },
    async next() {
      await this.saveData();
      this.useNext = true;
      this.$router.push({ name: "wor-trial-confirm-dates" });
    },
    goBack() {
      switch (this.currentCase.trialAction) {
        case trialActionsString()[0]: // Request for Warrant of Removal - Default Judgment
        case trialActionsString()[1]: // Request for Warrant of Removal - Judgment for Possession
          this.$router.push({ name: "wor-trial-action" });
          break;
        case trialActionsString()[2]: // Request for Warrant of Removal - Breach of Consent - Pay and Stay
          if (this.currentCase.breachTerms[1].selected)
            this.$router.push({ name: "wor-trial-breach-other-conditions" });
          else this.$router.push({ name: "wor-trial-confirm-breach" });
          break;
        case trialActionsString()[3]: // Request for Warrant of Removal - Breach of Consent - Vacate with Payment Plan
          if (this.currentCase.breachTerms[2].selected)
            this.$router.push({ name: "wor-trial-breach-other-conditions" });
          else if (this.currentCase.breachTerms[1].selected)
            this.$router.push({ name: "wor-trial-confirm-breach" });
          else this.$router.push({ name: "wor-trial-move-out-date" });
          break;
        case trialActionsString()[4]: // Request for Warrant of Removal - Breach of Consent - Vacate without Payment Plan
          this.$router.push({ name: "wor-trial-move-out-date" });
          break;
      }
    },
    formatDay(day) {
      const number = parseInt(day);
      let suffix;
      if (number > 3 && number < 21) suffix = "th";
      switch (number % 10) {
        case 1:
          suffix = "st";
          break;
        case 2:
          suffix = "nd";
          break;
        case 3:
          suffix = "rd";
          break;
        default:
          suffix = "th";
          break;
      }
      return day + suffix;
    },
    applyChangeInCharge() {
      this.payments = this.payments.map((item) => {
        return {
          ...item,
          dateForSort: this.$dayjs(item.date).format("YYYY-MM-DD"),
          amount: 0,
        };
      });
      this.ledgerData = [...this.charges, ...this.payments];

      this.ledgerData.sort((a, b) =>
        a.dateForSort > b.dateForSort
          ? 1
          : b.dateForSort > a.dateForSort
          ? -1
          : 0
      );
      this.totalBaseRent = this.ledgerData.reduce(
        (result, item) => result + item.amount - item.payment,
        0
      );

      this.totalDue = this.ledgerData.reduce(
        (result, item) => result + item.amount - item.payment,
        0
      );

      const ledgerData = this.ledgerData.slice();
      for (let i = 0; i < ledgerData.length; i++) {
        if (i === 0) {
          ledgerData[i].totalBalance =
            ledgerData[i].amount - ledgerData[i].payment;
        } else {
          ledgerData[i].totalBalance =
            ledgerData[i - 1].totalBalance +
            ledgerData[i].amount -
            ledgerData[i].payment;
        }
      }
      this.ledgerData = ledgerData;
      this.saveData();
    },
    addPayment(newPayments) {
      this.payments = [...this.payments, ...newPayments];
      this.addPayDialogOpen = false;
      this.applyChangeInCharge();
    },
    savePayAmount() {
      this.applyChangeInCharge();
    },
    clearPayAmount(item) {
      const index = this.payments.indexOf(item);
      this.payments.splice(index, 1);
      this.applyChangeInCharge();
    },
  },
};
</script>

<style scoped>
.ledger-table {
  border-top: 1px solid lightgray !important;
}
</style>
