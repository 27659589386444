import Vue from "vue";
import useRequest from "@/utils/useRequest";

export default {
  namespaced: true,
  state: {
    attorneys: [],
    loaded: false,
  },
  mutations: {
    set_attorneys(state, attorneys) {
      Vue.set(state, "attorneys", attorneys);
    },
    set_loaded(state, loaded) {
      Vue.set(state, "loaded", loaded);
    },
  },
  actions: {
    async loadAttorneys({ commit, rootGetters }) {
      if (!rootGetters.isAuthenticated) {
        console.log("unauthenticated, I will not load profile infos");
        return;
      }
      await useRequest({
        path: `allAttorneys`,
        showLoading: true,
        onSuccess: (attorneys) => commit("set_attorneys", attorneys),
      });
    },
  },
  getters: {
    attorneys: (state) => state.attorneys,
    loaded: (state) => state.loaded,
  },
};
