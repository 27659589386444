<template>
  <svg viewBox="0 0 30 27.809">
    <path
      class="a svg-icon"
      d="M63.67,104.043H91.52a.938.938,0,0,0,0-1.45L77.6,94.4,63.67,102.59S62.706,103.457,63.67,104.043Zm28.759,17.041-1.68-1.46a.842.842,0,0,0-.473-.144l-25.019.038a.984.984,0,0,0-.494.137l-1.978,1.436a.454.454,0,0,0-.185.305v.641c0,.093.137.171.309.171H92.291c.168,0,.309-.075.309-.171V121.4A.548.548,0,0,0,92.429,121.085Zm-4.412-15.907h2.564V118.03H88.017Zm-4.2,0h2.564V118.03H83.82Zm-14.618,0h2.564V118.03H69.2Zm-4.2,0h2.564V118.03H65.007ZM77.65,118.013a2.034,2.034,0,0,0,.576-.082v-1.556a3.165,3.165,0,0,0,1.82-1.028,2.831,2.831,0,0,0,.624-1.858,3.215,3.215,0,0,0-.161-1.094,2.067,2.067,0,0,0-.449-.737,2.633,2.633,0,0,0-.706-.518c-.278-.144-.586-.288-.926-.429l-.806-.336q-.2-.082-.4-.175a1.841,1.841,0,0,1-.363-.223.914.914,0,0,1-.254-.315,1.03,1.03,0,0,1-.093-.456.962.962,0,0,1,.12-.5.839.839,0,0,1,.309-.3,1.157,1.157,0,0,1,.422-.141,3.591,3.591,0,0,1,.463-.034,5.661,5.661,0,0,1,.672.034c.2.024.374.051.531.086a3.185,3.185,0,0,1,.442.134c.137.055.274.106.408.161a4.713,4.713,0,0,0,.429-1.529,10.129,10.129,0,0,0-1.046-.3,7.1,7.1,0,0,0-1.035-.154v-1.382a2.272,2.272,0,0,0-.59-.079,2.162,2.162,0,0,0-.576.079V106.7a3.078,3.078,0,0,0-1.892.847,2.608,2.608,0,0,0-.686,1.906,2.852,2.852,0,0,0,.141.953,2.035,2.035,0,0,0,.4.679,2.639,2.639,0,0,0,.624.5,7.354,7.354,0,0,0,.806.4l.713.309c.151.072.309.141.47.2a1.944,1.944,0,0,1,.435.23,1.1,1.1,0,0,1,.322.343.992.992,0,0,1,.127.531,1.016,1.016,0,0,1-.463.932,2.482,2.482,0,0,1-1.31.288,6.082,6.082,0,0,1-1.059-.086,5.294,5.294,0,0,1-1.059-.315c-.1.288-.185.562-.254.826a4.934,4.934,0,0,0-.147.785c.223.089.439.165.644.23s.415.113.624.154a5.58,5.58,0,0,0,.665.086c.233.017.5.027.792.027h.106v1.423A2.667,2.667,0,0,0,77.65,118.013Z"
      transform="translate(-62.6 -94.4)"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
