<template>
  <v-app style="background-color: #f8fafb">
    <LoadingOverlay :active.sync="loading" color="#4DCCC4" />
    <div v-if="isDesktopDevice || ignoreWidth" style="position: relative">
      <Navigation
        v-if="!isAuthRoute"
        style="margin-left: 20px; height: 95vh; top: 5vh; width: 80px"
      />
      <header v-if="!isAuthRoute">
        <Toolbar class="display-control space-left" />
      </header>
      <v-main
        :class="!isAuthRoute ? 'space-left' : ''"
        style="min-height: calc(100vh - 120px)"
      >
        <v-alert
          v-if="globalError"
          class="d-block mx-8 mt-4 mb-0 error-alert"
          outlined
          elevation="2"
          color="white"
        >
          <v-btn
            color="info"
            icon
            class="float-right btn--plain"
            @click="
              $store.commit('global/set_global_error', null, { root: true })
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-icon
            style="height: 70px; width: 70px; margin-left: 37%"
            size="xxx-large"
            color="primary"
          >
            mdi-alert
          </v-icon>
          <div class="secondary--text px-12" style="font-size: 20px">
            {{
              globalError === "Network Error"
                ? "Internet connection not stable"
                : globalError
            }}
          </div>

          <v-divider
            v-if="
              globalError === 'Your session is expired. Please sign back in.'
            "
            class="my-4 info"
            style="opacity: 0.22"
          ></v-divider>

          <v-row
            v-if="
              globalError === 'Your session is expired. Please sign back in.'
            "
          >
            <v-btn
              style="width: 100%; border-radius: 5px"
              tile
              color="primary"
              @click="logout()"
            >
              LOG OUT
            </v-btn>
          </v-row>
        </v-alert>
        <router-view />
      </v-main>

      <footer v-if="!isAuthRoute" class="footer-container">
        <div class="secondary--text footer-grid">
          <div>
            © {{ currentYear }} FileEvictions. All Rights Reserved, The
            information provided on FileEvictions (“Site”) is not legal advice
            and the Service provided by this Site are not legal services.
            FileEvictions is not a law firm or a legal representative. Access
            and use of this Site is subject to our
            <a
              class="accent--text small-font no-text-dec"
              href="/terms"
              @click.prevent="handleNavigationLink('terms')"
            >
              Terms of Service
            </a>
            and
            <a
              class="accent--text small-font no-text-dec"
              href="https://fileevictions.com/privacy-policy/"
              target="_blank"
              >Privacy Policy</a
            >.
          </div>
        </div>
      </footer>
    </div>
    <div v-else>
      <div class="mobile-responsive-warning">
        <img src="/images/logo.svg" alt="logo" height="50" />
        <p class="secondary--text">
          For the best viewing experience, please access the software from a
          desktop device. We are working on creating a flawless mobile
          experience for you.
        </p>
        <v-btn
          rounded
          class="primary white--text text-center pa-6 font-weight-medium mt-auto capital--btn"
          @click="ignoreWidth = true"
        >
          <span class="" style="font-weight: 600 !important"
            >Continue anyway</span
          >
        </v-btn>
      </div>
    </div>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
import Toolbar from "./components/Toolbar";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Navigation,
    Toolbar,
  },
  data() {
    return {
      currentYear: "",
      deviceWidth: null,
      ignoreWidth: false,
    };
  },
  computed: {
    ...mapGetters(["loading"]),
    globalError() {
      return this.$store.getters["global/globalError"]?.message;
    },
    isDesktopDevice() {
      return this.deviceWidth > 900;
    },
    isAuthRoute() {
      return this.$route.meta?.isAuthRoute;
    },
  },
  created() {
    const date = new Date();
    this.currentYear = date.getFullYear();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    onCloseError() {
      this.$store.commit("global/set_global_error", null);
    },
    handleResize() {
      this.deviceWidth = window.innerWidth;
    },
    logout() {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin + "/login",
        },
      });
    },
  },
};
</script>

<style>
html {
  font-size: 20px !important;
  overflow: auto;
}
.navlinks.v-list .v-list-item--active .v-list-item-icon {
  color: #4dccc4;
}
.navlinks.v-list .v-list-item--active {
  color: #44ddcc;
  box-shadow: 3px 0 0 #4dccc4 inset;
}
.nav-link.theme--light.v-list-item:not(.v-list-item--active):not(
    .v-list-item--disabled
  ) {
  color: #afc2ce !important;
}

span.v-btn__content {
  text-transform: capitalize;
  letter-spacing: 0.7px;
}
.theme--light.v-btn {
  color: #003a60;
}
.v-date-picker-header__value .primary--text button {
  font-weight: 500;
  font-size: 20px;
}
.theme--light.v-date-picker-header
  .v-date-picker-header__value:not(.v-date-picker-header__value--disabled)
  .primary--text
  button:not(:hover):not(:focus) {
  color: #003a60;
}
button.v-btn.v-btn--flat.v-btn--icon.v-btn--round.theme--light.v-size--default {
  color: #4dccc4;
}
/* .theme--light.v-input--switch .v-input--switch__thumb {
  color: #9AABB5 !important;
} */

.theme--light.v-input--switch .v-input--switch__track {
  color: #dae3e9 !important;
}
.v-dialog > .v-card > .v-card__title {
  word-break: normal;
}
.v-application {
  font-family: Hind, sans-serif;
}
.full-width {
  width: 100%;
}
.hidden-item {
  visibility: hidden;
}

.non-click {
  pointer-events: none;
}

.elevated-bar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 1px 20px 45px #00000007;
  border-radius: 10px;
  opacity: 1;
  min-height: 100px;
  width: 100%;
  font-weight: 500;
  font-size: 26px;
  cursor: pointer;
}

.elevated-bar-btn {
  background: #f0f5f6 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  opacity: 1;
  width: 113px;
  height: 100%;
}

.elevated-bar:hover {
  filter: brightness(98%);
}

.selection,
.selection-box {
  background: #fafbfc 0% 0% no-repeat padding-box;
  border: 1px solid #8ba4b4;
  border-radius: 20px;
  opacity: 1;
  width: 250px;
  min-height: 110px;
  color: #8ba4b4;
  cursor: pointer;
}

.selection:hover,
.selection-selected {
  background: #fafbfc 0% 0% no-repeat padding-box;
  border: 2px solid #4dccc4;
  border-radius: 20px;
  opacity: 1;
  width: 250px;
  height: 110px;
  color: #4dccc4;
  cursor: pointer;
}

.selection-border {
  border: 1px solid #8ba4b4;
  border-radius: 20px;
  color: #8ba4b4;
}

.selection-border-selected {
  border: 2px solid #4dccc4;
  border-radius: 20px;
  color: #003a60;
}

.custom-overline {
  text-transform: uppercase;
  letter-spacing: 1.4px;
}

.spaced-text {
  letter-spacing: 0.29px;
}

.no-text-dec {
  text-decoration: none;
}

.v-tab {
  text-transform: none !important;
}

[class*=" icofont-"],
[class^="icofont-"] {
  line-height: unset;
}

.btn--plain:hover:before {
  background-color: transparent;
}

.btn--plain:before {
  display: none;
}
.v-ripple__animation {
  display: none;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.centered-input input {
  text-align: center;
}

.v-application a {
  text-decoration: none;
}

.btn-style-text {
  letter-spacing: 0.7px;
  font-weight: 500;
}

.custom-heading {
  font-size: 40px;
  letter-spacing: 0.15px;
  font-weight: 600;
}

.custom-title {
  font-weight: 500 !important;
  color: #003a60 !important;
  font-size: 48px;
}

.custom-subtitle {
  font-weight: 500 !important;
  color: #003a60 !important;
  font-size: 28px;
}

.custom-card {
  box-shadow: 15px 15px 40px #00000029;
  border-radius: 20px;
}

/* VALIDATION MESSAGES */
.v-messages__message {
  line-height: 18px !important;
}

.v-messages {
  font-size: 16px !important;
}

.error-message {
  font-size: 16px;
  line-height: 18px;
  color: #ff5252;
}
.accent--error-message {
  font-size: 16px;
  line-height: 18px;
  color: #ff854e;
}

.v-tooltip__content {
  background: transparent !important;
  opacity: 1 !important;
}
/* .v-input--is-readonly.v-text-field.v-text-field--solo .v-input__control input {
  color: #53809D;
} */

.primary-checkbox .theme--light.v-icon {
  color: #4dccc4;
}

/* Remove buttons from number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* this will hide the scrollbar in webkit based browsers - safari, chrome, etc */
.scroll-hide::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}
.scroll-hide {
  /* this will hide the scrollbar in mozilla based browsers */
  overflow: -moz-scrollbars-none;
  /* this will hide the scrollbar in internet explorers */
  -ms-overflow-style: none;
}

.mobile-responsive-warning {
  max-width: 570px;
  text-align: center;
  margin: 200px auto 0;
}

.mobile-responsive-warning img {
  margin-bottom: 36px;
}

/* controls nav display toggle between desktop and mobile */
@media only screen and (max-width: 768px) {
  .display-control {
    display: none;
  }
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  background-color: #f8fafb;
  font-size: 0.7rem;
  margin: 40px 40px 40px 150px;
}
.footer-container {
  position: relative;
  /* position: absolute; */
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0;
}
.error-alert {
  position: fixed;
  top: 100px;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px !important;
  background-color: white !important;
  /* width: 80%; */
}
p,
input,
.v-select__selections {
  color: #003a60 !important;
}
.space-left {
  margin-left: 50px;
}

/* Table Search section style */
.table-custom-search i {
  color: #8ba4b4;
  margin-right: 20px;
}
.table-custom-search input {
  outline: none;
  color: #8ba4b4;
  font-size: 18px;
}
.table-custom-search input::placeholder {
  color: #8ba4b4;
}
.sort-actions i {
  cursor: pointer;
  width: 10px;
}
.sort-actions .custom-sort-icon {
  color: #8ba4b4;
  cursor: pointer;
}
.sort-actions .custom-sort-icon.active {
  color: #4dccc4;
}
.page-subheader {
  height: 70px;
}
.v-menu__content .v-list-item__title {
  letter-spacing: 0.1rem !important;
}

.custom-banner {
  position: relative;
  padding: 20px 30px 30px;
  border-radius: 15px;
  display: flex;
  align-items: baseline;
}

.eviction-banner {
  background: #ff854e39 0% 0%;
  border: 6px solid #ff854e;
}

.custom-banner .close-icon {
  position: absolute;
  cursor: pointer;
}

.custom-banner .text-part {
  margin-left: 20px;
}

.banner-title {
  font-size: 30px;
  line-height: 26px;
  font-weight: 500;
  margin-bottom: 16px;
}

.banner-description {
  margin: 0;
}

.upcoming-banner {
  background: #6a7fdb38;
  border: 6px solid #6a7fdb;
}

/* Data Table style */
.custom-data-table .v-data-table__wrapper {
  padding: 0px 50px 50px;
}
.custom-data-table .v-data-table__wrapper > table {
  box-shadow: 15px 15px 40px #00000029;
  border-radius: 20px;
  min-width: 1000px;
}
.custom-data-table
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  height: 65px !important;
  background-color: white;
  text-transform: uppercase;
}

.theme--light.v-data-table {
  background-color: transparent !important;
}

.theme--light.v-data-table thead tr th:first-child {
  border-top-left-radius: 20px;
}

.theme--light.v-data-table thead tr th:first-child {
  border-top-left-radius: 20px !important;
}

.theme--light.v-data-table thead tr th:last-child {
  border-top-right-radius: 20px;
}

.theme--light.v-data-table thead tr th:last-child {
  border-top-right-radius: 20px !important;
}

/* Table Body style */
.theme--light.v-data-table tbody {
  background-color: white;
  /* border-radius: 20px; */
}

.theme--light.v-data-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
}
.theme--light.v-data-table tbody tr:last-child:hover td:first-child {
  border-bottom-left-radius: 20px !important;
}

.theme--light.v-data-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}
.theme--light.v-data-table tbody tr:last-child:hover td:last-child {
  border-bottom-right-radius: 20px !important;
}

/* Table Footer style */
.theme--light.v-data-table .v-data-footer {
  border-top: none;
  justify-content: space-between;
  position: relative;
  padding: 0 60px;
}

.theme--light.v-data-table .v-data-footer .v-data-footer__select {
  margin-left: 0 !important;
  color: #1e5273;
}

.v-data-footer__pagination {
  position: absolute;
  right: 155px;
  margin: 0 !important;
  color: #003a60;
  font-size: 14px;
  font-weight: 600;
}

.v-data-footer__icons-before {
  position: absolute;
  right: 250px;
}

.v-data-table.rounded-table.theme--light {
  border-radius: 20px;
}
.v-data-table td.custom-expanded-row {
  background-color: #f8fafb;
  padding: 4px 16px;
}
.v-data-table tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

.v-data-table thead th.active.asc .v-data-table-header__icon {
  opacity: 1 !important;
}

.v-data-table thead th.active.desc .v-data-table-header__icon {
  opacity: 1 !important;
}

.v-data-table-header__icon {
  opacity: 1 !important;
}
.v-data-table-header tr th:first-child {
  padding-left: 36px !important;
}

/* Table Footer style */
.theme--light.v-data-table .v-data-footer {
  border-top: none !important;
  justify-content: space-between;
  position: relative;
  padding: 0 60px;
}

.theme--light.v-data-table .v-data-footer .v-data-footer__select {
  margin-left: 0 !important;
  color: #1e5273;
}

.v-data-footer__pagination {
  position: absolute;
  right: 155px;
  margin: 0 !important;
  color: #003a60;
  font-size: 14px;
  font-weight: 600;
}

.v-data-footer__icons-before {
  position: absolute;
  right: 250px;
}

.custom-data-table .v-data-footer__icons-before i,
.custom-data-table .v-data-footer__icons-after i {
  color: #ff854e !important;
}

.card-actions {
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #fafbfc;
  padding: 20px;
  padding-right: 48px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: flex-end;
  z-index: 15;
}
</style>
