import useRequest from "./useRequest";

const openSecuredFile = async ({ id, filename, folder }) => {
  const { data } = await useRequest({
    path: `presignedUrl/${folder}/${id}`,
  });
  if (!data) return;
  const { url, base64PDF } = data;

  if (url) {
    window.open(url, "_blank");
  } else if (base64PDF) {
    const newTab = window.open(id);
    newTab.document.write(
      `<title>${filename}</title>
      <style>body{margin:0;padding:0;overflow:hidden;}</style>`
    );
    newTab.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(base64PDF) +
        "'></iframe>"
    );
  }
};

export default openSecuredFile;
