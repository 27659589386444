<template>
  <svg viewBox="0 0 30 30">
    <path
      class="a svg-icon"
      d="M25.313,0a4.688,4.688,0,0,1,3.75,7.5L27.188,9.375,20.625,2.813,22.5.938A4.665,4.665,0,0,1,25.313,0ZM1.875,21.563,0,30l8.438-1.875L25.781,10.781,19.219,4.219ZM20.964,10.652,7.839,23.777,6.223,22.161,19.348,9.036Z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
