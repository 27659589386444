<template>
  <div :class="isCard ? '' : 'custom-data-table'">
    <v-data-table
      v-if="messages.length > 0"
      v-model="selected"
      :headers="headers"
      :header-props="{ 'sort-icon': 'mdi-menu-up' }"
      :must-sort="true"
      :items="messages"
      :search="search"
      :custom-filter="customFilter"
      item-key="ID"
      :class="isCard ? '' : 'my-table'"
      :items-per-page="itemsPerPage"
      :footer-props="
        isCard
          ? {}
          : {
              showFirstLastPage: true,
              firstIcon: 'mdi-skip-previous',
              lastIcon: 'mdi-skip-next',
              prevIcon: 'mdi-menu-left',
              nextIcon: 'mdi-menu-right',
              itemsPerPageText: 'Number of messages to display',
              pageText: pageText,
            }
      "
      @page-count="pageCount = $event"
      @update:options="updateOptions"
      @current-items="getFilteredMessages"
    >
      <template v-slot:item="{ item, index }">
        <tr
          @mouseover="rowHoverIndex = index"
          @mouseleave="rowHoverIndex = -1"
          :class="{ 'on-hover': rowHoverIndex === index }"
          class="data-row"
        >
          <td class="first-column">
            <v-select
              v-model="item.status"
              class="mt-6"
              background-color="#F0F5F6"
              item-color="#F6F9FA"
              rounded
              solo
              flat
              dense
              :items="statusList"
            />
          </td>
          <td>
            {{ item.date }}
          </td>
          <td v-if="!isCard">
            {{ item.company ? item.company : "N/A" }}: {{ item.attorneyName }}
          </td>
          <td
            v-if="!isCard"
            class="ml-4 accent--text"
            @click="caseOverview(item.caseId)"
            style="cursor: pointer"
          >
            {{ item.caseParties }}
          </td>
          <td v-if="!isCard">{{ item.docketNo ? item.docketNo : "LT-" }}</td>
          <td>{{ item.fromTo }}</td>
          <td>{{ item.subject }}</td>
          <td :style="!isCard ? 'width: 20%' : ''">
            <div class="d-flex">
              <div class="content" style="width: 85%">
                {{ item.content }}
              </div>
              <v-menu
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mt-0"
                    v-bind="attrs"
                    v-on="on"
                    v-on:click="showMessage(item)"
                  >
                    icofont-external-link
                  </v-icon>
                </template>

                <v-card class="px-8 secondary--text" style="width: 700px">
                  <v-card-title
                    >{{ item.caseParties }}
                    <span
                      class="ml-auto"
                      style="font-size: 20px; font-weight: normal"
                      >{{ item.status }}</span
                    ></v-card-title
                  >
                  <v-card-subtitle class="mt-1"
                    >{{
                      `${item.company ? item.company : "N/A"}: ${
                        item.attorneyName
                      }`
                    }}
                    <span class="ml-16">{{ item.date }}</span></v-card-subtitle
                  >
                  <v-card-text class="secondary--text">{{
                    item.content
                  }}</v-card-text>
                  <!-- <v-card-actions>
                    <v-btn
                      color="secondary"
                      class="mb-2 capital--btn font-weight-medium"
                      icon
                    >
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </v-card-actions> -->
                </v-card>
              </v-menu>
            </div>
          </td>
          <td>
            <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="accent_light"
                  class="white--text"
                  rounded
                  depressed
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>icofont-reply</v-icon> Reply
                </v-btn>
              </template>

              <v-card class="px-8 secondary--text" style="width: 700px">
                <v-card-title>
                  {{ item.caseParties }}
                </v-card-title>
                <v-card-subtitle class="mt-1"
                  >{{
                    `${item.company ? item.company : "N/A"}: ${
                      item.attorneyName
                    }`
                  }}
                  <span class="ml-16">{{ item.date }}</span></v-card-subtitle
                >
                <v-textarea class="mx-2 secondary--text" outlined rows="3" />
                <v-card-actions>
                  <v-btn
                    rounded
                    color="accent_light"
                    class="ml-auto mb-2 px-8 white--text capital--btn"
                    depressed
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </td>
        </tr>
        <td :colspan="headers.length" class="row-divider"></td>
      </template>
      <template v-if="messages.length !== 0" slot="body.append">
        <tr style="height: 30px"></tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MessageTable",
  props: {
    _messages: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: "",
    },
    filters: {
      type: Array,
      default: () => [],
    },
    isCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Status",
          value: "status",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Date",
          value: "date",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Attorney",
          value: "attorney",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "15%",
        },
        {
          text: "Parties",
          value: "caseParties",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "25%",
          sortable: false,
        },
        {
          text: "Docket No.",
          value: "docketNo",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "10%",
          sortable: false,
        },
        {
          text: "From / To",
          value: "fromTo",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
          width: "10%",
        },
        {
          text: "Subject",
          value: "subject",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Content",
          value: "content",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          class: "header-text secondary--text font-weight-medium",
          align: "center",
          sortable: false,
        },
      ],
      selected: [],
      itemsPerPage: 5,
      pageCount: 0,
      page: 1,
      rowHoverIndex: false,
      statusList: ["New", "Read", "Done"],
      messages: [],
      filteredMessages: [],
    };
  },
  computed: {
    ...mapGetters({
      firm: "firm",
    }),
    pageText() {
      return "Pages " + this.page + ` / ` + this.pageCount;
    },
  },
  watch: {
    filters: {
      handler(val) {
        if (!val.length) {
          this.messages = this._messages;
          return;
        }
        const _filters = val.filter(
          (item) => item.filterColumn && item.filterOperator && item.filterValue
        );
        if (_filters.length) {
          let _filteredMessages = this._messages;
          _filters.map((item) => {
            if (item.filterColumn == "DATE") {
              switch (item.filterOperator) {
                case "On or Before":
                  _filteredMessages = _filteredMessages.filter(
                    (msgItem) =>
                      this.$dayjs(msgItem.date).format("YYYY-MM-DD") <=
                      item.filterValue
                  );
                  break;
                case "On or After":
                  _filteredMessages = _filteredMessages.filter(
                    (msgItem) =>
                      this.$dayjs(msgItem.date).format("YYYY-MM-DD") >=
                      item.filterValue
                  );
                  break;
                case "On":
                  _filteredMessages = _filteredMessages.filter(
                    (msgItem) =>
                      this.$dayjs(msgItem.date).format("YYYY-MM-DD") ==
                      item.filterValue
                  );
                  break;
              }
            } else {
              _filteredMessages = _filteredMessages.filter((msgItem) => {
                switch (item.filterColumn) {
                  case "STATUS":
                    return msgItem.status == item.filterValue;
                  case "DATE":
                    return msgItem.date == item.filterValue;
                  case "ATTORNEY":
                    return (
                      `${msgItem.company ? msgItem.company : "N/A"}: ${
                        msgItem.attorneyName
                      }` == item.filterValue
                    );
                  case "PARTIES":
                    return msgItem.caseParties == item.filterValue;
                  case "DOCKET NO.":
                    return (msgItem.docketNo ?? "Pending") == item.filterValue;
                  case "FROM / TO":
                    return msgItem.fromTo == item.filterValue;
                  case "SUBJECT":
                    return msgItem.subject == item.filterValue;
                  case "CONTENT":
                    return msgItem.content == item.filterValue;
                }
              });
            }
          });
          this.messages = _filteredMessages;
        }
      },
      deep: true,
    },
    _messages() {
      this.messages = this._messages;
    },
  },
  async created() {
    if (this.isCard) {
      this.headers = [
        {
          text: "Status",
          value: "status",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "150",
          sortable: false,
        },
        {
          text: "Date",
          value: "date",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "150",
          sortable: false,
        },
        {
          text: "From / To",
          value: "fromTo",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          width: "150",
          sortable: false,
        },
        {
          text: "Subject",
          value: "subject",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          text: "Content",
          value: "content",
          class: "header-text secondary--text font-weight-medium",
          align: "left",
          sortable: false,
        },
        {
          class: "header-text secondary--text font-weight-medium",
          align: "center",
          sortable: false,
        },
      ];
    }
    this.messages = this._messages;
  },
  methods: {
    async caseOverview(caseId) {
      await this.$store.dispatch("eviction/loadCase", {
        caseId,
        forceLoad: true,
      });
      this.$router.push(`case-view/${caseId}/overview`);
    },
    async showMessage(item) {
      if (item.status !== "Read" && item.recipientId === this.firm.userId)
        await this.$store.dispatch("message/updateMessage", {
          messageId: item.messageId,
          status: "Read",
        });
    },
    getFilteredMessages(filteredMessages) {
      this.filteredMessages = filteredMessages;
    },
    exportExcel() {
      const headersText = this.headers
        .slice(0, this.headers.length - 1)
        .map((item) => item.text);
      let csv_string = headersText.join(", ") + "\n";
      this.filteredMessages.forEach((item) => {
        csv_string += item.status;
        csv_string += `,${item.date}`;
        csv_string += `,${item.company ? item.company : "N/A"}: ${
          item.attorneyName
        }`;
        csv_string += `,${item.caseParties}`;
        csv_string += `,${item.docketNo ?? "LT-"}`;
        csv_string += `,${item.fromTo}`;
        csv_string += `,${item.content}`;
        csv_string += "\n";
      });

      const link = document.createElement("a");
      link.style.display = "none";
      link.setAttribute("target", "_blank");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
      );
      link.setAttribute("download", "export_messages.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    customFilter(_value, search, items) {
      return (
        (items.status !== null &&
          items.status
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())) ||
        (items.company !== null &&
          items.attorney !== null &&
          `${items.company.toString()} ${items.attorneyName.toString()}`
            .toLowerCase()
            .includes(search.toString().toLowerCase())) ||
        (items.caseParties !== null &&
          items.caseParties
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())) ||
        (items.docketNo !== null &&
          items.docketNo
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())) ||
        (items.fromTo !== null &&
          items.fromTo
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())) ||
        (items.content !== null &&
          items.content
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase()))
      );
    },
    updateOptions(option) {
      this.page = option.page;
    },
  },
};
</script>

<style scoped>
.content {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.data-row {
  color: #003a60 !important;
  height: 80px;
}
.data-row > td {
  border-bottom: none !important;
}
.data-row:hover {
  background-color: transparent !important;
}
.first-column {
  border-left: 8px solid transparent;
}
.on-hover > .first-column {
  border-left: 8px solid #4dccc4;
}
.row-divider {
  height: 1px !important;
  background: #c3d1d2 !important;
}
.status-row {
  background-color: #f8fafb !important;
  color: #003a60 !important;
}
.status-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  align-items: center;
  height: 65px !important;
  padding-right: 10px;
}
.v-menu__content {
  margin-top: 50px;
}
.v-icon.v-icon::after {
  background-color: white;
}
</style>
