<template>
  <svg viewBox="0 0 1000 1000">
    <path
      class="a svg-icon"
      d="M712.3 0h-54.5c-21.199999999999932 0-38.59999999999991 17.4-38.59999999999991 38.6v57.9h-293.00000000000006c-42.5 0-77.19999999999999 34.80000000000001-77.19999999999999 77.19999999999999v749c0 42.5 34.80000000000001 77.19999999999993 77.19999999999999 77.19999999999993h347.50000000000006c42.5 0 77.19999999999993-34.69999999999993 77.19999999999993-77.19999999999993v-884.1c0-21.200000000000024-17.399999999999977-38.60000000000002-38.60000000000002-38.60000000000002z m-303.69999999999993 918.2h-91.70000000000005v-91.70000000000005h91.70000000000005v91.70000000000005z m0-137.60000000000002h-91.70000000000005v-91.70000000000005h91.70000000000005v91.70000000000005z m136.39999999999998 137.60000000000002h-91.69999999999999v-91.70000000000005h91.69999999999999v91.70000000000005z m0-137.60000000000002h-91.69999999999999v-91.70000000000005h91.69999999999999v91.70000000000005z m136.29999999999995 137.60000000000002h-91.69999999999993v-91.70000000000005h91.69999999999993v91.70000000000005z m0-137.60000000000002h-91.69999999999993v-91.70000000000005h91.69999999999993v91.70000000000005z m14.5-189.80000000000007c0 21.200000000000045-17.399999999999977 38.60000000000002-38.59999999999991 38.60000000000002h-313.70000000000005c-21.19999999999999 0-38.60000000000002-17.399999999999977-38.60000000000002-38.60000000000002v-364.4c0-21.19999999999999 17.400000000000034-38.599999999999966 38.60000000000002-38.599999999999966h313.70000000000005c21.199999999999932 0 38.59999999999991 17.399999999999977 38.59999999999991 38.599999999999994v364.4z"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
