<template>
  <div>
    <v-card
      class="event-detail-modal"
      style="border-radius: 20px"
      :style="`border-bottom: 20px solid ${eventData.color}`"
    >
      <div class="close-btn">
        <v-btn color="info" icon @click="onCancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-form>
        <vue-scroll :ops="ops" ref="scrollRef">
          <div class="pa-12 px-16 secondary--text">
            <v-card-title class="mb-4 font-weight-medium">
              <v-row class="d-flex justify-space-between">
                <span style="font-size: 30px">{{ eventData.title }}</span>
              </v-row>
            </v-card-title>
            <div class="mt-4 px-4" style="font-size: 18px">
              <v-row class="d-flex justify-space-between">
                <div>{{ $dayjs(eventData.date).format("MMMM DD, YYYY") }}</div>
                <div>
                  <v-icon color="secondary" size="medium"
                    >icofont-clock-time</v-icon
                  >
                  {{ tConvert(eventData.startTime.slice(0, 5)) }} -
                  {{ tConvert(eventData.endTime.slice(0, 5)) }}
                </div>
              </v-row>
            </div>
            <div class="mt-4 px-4" style="font-size: 18px">
              <div class="mt-4">
                <v-row class="d-flex font-weight-medium mt-8">
                  <span class="info--text">Parties</span>
                  <span class="secondary--text ml-4">{{
                    eventData.caseParties
                  }}</span>
                </v-row>
                <v-row class="font-weight-medium mt-4">
                  <span class="info--text">Docket</span>
                  <span
                    class="secondary--text accent--text ml-4"
                    style="cursor: pointer"
                    @click="goToCase"
                    >{{ `${eventData.county} ${eventData.docketNo}` }}</span
                  >
                </v-row>
                <v-row class="d-flex font-weight-medium mt-4">
                  <span class="info--text">Attorney</span>
                  <span class="secondary--text ml-4">{{
                    eventData.attorneyName
                  }}</span>
                </v-row>
              </div>
            </div>
          </div>
        </vue-scroll>
      </v-form>
    </v-card>

    <div v-if="eventData.handlingConflict" class="mt-2">
      <v-btn
        depressed
        tile
        style="width: 40%; border-bottom-left-radius: 20px"
        class="white--text text-center pa-8 font-weight-medium mt-auto capital--btn accent_light"
        @click="$emit('editEvent')"
      >
        <span class="" style="font-weight: 600 !important">EDIT</span>
      </v-btn>
      <v-btn
        tile
        style="width: 60%; border-bottom-right-radius: 20px"
        class="white--text text-center pa-8 font-weight-medium mt-auto capital--btn accent"
        @click="$emit('doubleBook', true)"
      >
        <span class="" style="font-weight: 600 !important">DOUBLE BOOK</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EventDetailDialog",
  props: {
    eventData: Object,
  },
  data() {
    return {
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          opacity: 0,
          size: "10px",
          specifyBorderRadius: false,
          gutterOfEnds: "85px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "10px",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      cases: "eviction/cases",
    }),
  },
  methods: {
    onCancel() {
      this.$emit("close");
      this.$refs.scrollRef.scrollTo({ x: 0, y: 0 }, 0);
    },
    deleteEvent(eventId) {
      this.$emit("deleteEvent", eventId);
    },
    async goToCase() {
      await this.$store.dispatch("eviction/loadCase", {
        caseId: this.eventData.caseId,
        forceLoad: true,
      });
      this.$router.push(`case-view/${this.eventData.caseId}/overview`);
    },
    tConvert(time) {
      // Check correct time format and split into components
      time = time.toString().split(":");

      if (time.length > 1) {
        // If time format correct
        time[2] = time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = time[0] % 12 || 12; // Adjust hours
      }
      return time[0] + ":" + time[1] + time[2]; // return adjusted time or original string
    },
  },
};
</script>

<style>
.event-detail-modal {
  overflow-y: visible;
}
.event-detail-modal .v-text-field__details {
  display: none;
}
.v-dialog {
  box-shadow: none;
}
</style>

<style scoped>
.__vuescroll >>> .__panel {
  overscroll-behavior: contain;
}
.close-btn {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 1;
}
.theme--light.v-divider {
  border-color: grey;
}
.v-divider--vertical {
  align-self: center;
  height: 20px;
}
.v-btn >>> .v-btn__content {
  opacity: 1 !important;
}
.v-menu__content {
  background-color: white;
  border-radius: 15px;
  box-shadow: 15px 15px 40px #00000029 !important;
}
.v-list {
  padding: 20px 10px;
}
</style>
