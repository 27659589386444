<template>
  <svg viewBox="0 0 30 26.287">
    <path
      class="a svg-icon"
      d="M85.729,130.872v-1.121H91.19v-2.108a1.64,1.64,0,0,0-1.625-1.659H85.729V121.89a3.037,3.037,0,0,0-.617-1.69c-.439-.521-.874-1.039-1.32-1.553-.339-.411-.689-.816-1.035-1.227a2.34,2.34,0,0,0-1.563-.72H70.21a.955.955,0,0,0-.943.96v5.989h-3.85a1.64,1.64,0,0,0-1.632,1.652v4.446h5.482v1.121H62.5l1.289,10.552a1.6,1.6,0,0,0,1.628,1.567H89.562a1.6,1.6,0,0,0,1.628-1.567l1.31-10.552H85.729Zm-15.454-1.124V118.99a.838.838,0,0,1,.83-.843h9.633a2.057,2.057,0,0,1,1.371.634c.305.36.61.713.915,1.076.391.456.775.908,1.159,1.364a2.664,2.664,0,0,1,.538,1.481v8.173H70.275v-1.128Zm12.3-6.637H72.239a.359.359,0,0,0-.357.36v.36a.357.357,0,0,0,.357.36H82.579a.359.359,0,0,0,.357-.36v-.36A.359.359,0,0,0,82.579,123.111Zm0,2.331H72.239a.359.359,0,0,0-.357.36v.36a.357.357,0,0,0,.357.36H82.579a.359.359,0,0,0,.357-.36v-.36A.359.359,0,0,0,82.579,125.442Zm0,2.547H72.239a.359.359,0,0,0-.357.36v.36a.357.357,0,0,0,.357.36H82.579a.359.359,0,0,0,.357-.36v-.36A.359.359,0,0,0,82.579,127.989Z"
      transform="translate(-62.5 -116.7)"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
