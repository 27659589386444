<template>
  <div class="secondary--text pr-4">
    <div
      class="d-flex secondary--text font-weight-medium mb-12"
      style="font-size: 1.25rem"
    >
      <div class="secondary--text font-weight-medium ml-16 mr-10">Notes</div>
      <v-btn
        fab
        depressed
        color="primary"
        height="45"
        width="45"
        @click="addNote"
      >
        <span class="white--text font-weight-regular" style="font-size: 50px"
          >+</span
        >
      </v-btn>
    </div>

    <v-card
      v-for="(note, index) in notes"
      :key="index"
      class="mt-4 mb-8 pt-4 px-8 py-4 rounded-card d-flex flex-column justify-space-between"
      style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
    >
      <div class="ml-auto">
        <v-btn
          v-show="!note.show"
          icon
          color="primary"
          class="btn--plain"
          @click="note.show = true"
        >
          <v-icon large> mdi-menu-down </v-icon>
        </v-btn>
        <v-btn
          v-show="note.show"
          icon
          color="primary"
          class="btn--plain"
          @click="note.show = false"
        >
          <v-icon large> mdi-menu-up </v-icon>
        </v-btn>
      </div>
      <div v-if="note.show">
        <div class="d-flex flex-column secondary--text ml-12 mr-16">
          <v-text-field
            v-if="note.editing"
            v-model="note.subject"
            color="secondary"
            class="font-weight-medium"
            solo
            flat
            dense
            placeholder="Subject"
            background-color="#F0F5F6"
          />
          <span v-else class="font-weight-medium">{{ note.subject }}</span>
          <v-textarea
            v-if="note.editing"
            v-model="note.content"
            color="secondary"
            solo
            flat
            dense
            rows="3"
            placeholder="Please input content"
            background-color="#F0F5F6"
          />
          <span v-else>
            {{ note.content }}
          </span>
        </div>
        <div class="mt-4 d-flex flex-row align-center">
          <v-btn
            icon
            color="info"
            class="btn--plain ml-auto mr-2"
            @click="editNote(index)"
          >
            <v-icon>{{
              note.editing ? "mdi-content-save-outline" : "mdi-pencil-outline"
            }}</v-icon>
          </v-btn>
          <v-icon
            v-if="!note.editing"
            color="info"
            size="x-large"
            @click="deleteNote(index)"
            >icofont-close-circled</v-icon
          >
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "CaseNotesCard",
  data() {
    return {
      notes: [
        // {
        //   subject: "Subject of Note",
        //   content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiumsmod tempor incididunt ut labore et dolore magna aliqua. Ut enim minim veniam, quis nostrud exercitation ullamco laboris nisi ut.`,
        //   show: true,
        //   editing: false,
        // },
      ],
    };
  },
  methods: {
    addNote() {
      this.notes.push({
        subject: "",
        content: "",
        show: true,
        editing: true,
      });
    },
    editNote(index) {
      this.notes[index].editing = !this.notes[index].editing;
    },
    deleteNote(index) {
      this.notes.splice(index, 1);
    },
  },
};
</script>

<style></style>
