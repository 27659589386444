import caseSteps from "@/utils/initialCaseSteps.json";

export default function getCaseStatusLabel(currentCase) {
  if (!currentCase?.actions) return "";
  const actionId = currentCase.actions[0]?.actionId;
  switch (actionId) {
    case 181: // Complete Document
      return `Verified Complaint | ${
        caseSteps[currentCase.status]?.label || currentCase.status
      }`;
    case 21: // Enter Court Information
    case 191: // Complete CLORS
      return `Verified Complaint | Completed: ${currentCase.statusDate} `;
    case 201: // Follow-up with Court
      return `Verified Complaint & File Certification of Lease and Landlord Registration | Completed: ${currentCase.statusDate} `;
    case 211: // Request from sheriff for removal of Tenant
      return `Warrant of Removal | Completed`;
    default:
      return "???";
  }
}
