export default {
  namespaced: true,
  state: {
    globalError: null,
  },
  mutations: {
    set_global_error: (state, error) => {
      state.globalError = error;
    },
    clear_global_error: (state) => {
      if (state.globalError) state.globalError = null;
    },
  },
  actions: {},
  getters: {
    globalError: (state) => state.globalError,
  },
};
