<template>
  <div>
    <div class="px-16 sticky-header">
      <div class="d-flex mb-8 page-subheader">
        <div class="d-flex align-center">
          <div class="secondary--text custom-heading mr-12">Attorneys</div>
        </div>

        <div class="d-flex pl-8 mt-4 ml-auto">
          <!-- search start -->
          <v-menu
            v-model="searchMenu"
            :close-on-content-click="false"
            :nudge-width="200"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="px-4 subheader-btn ml-auto"
                v-bind="attrs"
                v-on="on"
              >
                <i class="icofont-search-1" color="grey" />&nbsp;Search
              </v-btn>
            </template>

            <v-card
              style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
            >
              <div class="px-8 py-4 table-custom-search">
                <input
                  v-model="search"
                  type="text"
                  placeholder="Search Attorneys"
                />
              </div>
            </v-card>
          </v-menu>
          <!-- search end -->
        </div>
      </div>
    </div>

    <AttorneyTable :search="search" />
  </div>
</template>

<script>
import AttorneyTable from "@/components/tables/AttorneyTable";

export default {
  name: "Attorneys",
  components: {
    AttorneyTable,
  },
  data() {
    return {
      search: "",
      searchMenu: false,
    };
  },
  activated() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.v-menu__content {
  margin-top: 40px;
  border-radius: 15px;
}
</style>
