<template>
  <div class="py-4 px-12 mx-4">
    <div class="d-flex align-center justify-space-between my-8">
      <div class="d-flex flex-row align-center secondary--text">
        <v-icon color="primary" size="xxx-large" @click="$router.push('/')"
          >mdi-arrow-left-drop-circle</v-icon
        >
        <span class="pl-8 font-weight-medium" style="font-size: 40px">
          Case ID:
          <span style="text-transform: uppercase">
            {{
              `${currentCase.attorneyInfo.firstName}${currentCase.attorneyInfo.lastName}`.slice(
                0,
                3
              ) +
              "-" +
              `${currentCase.clientInfo.firstName}${currentCase.clientInfo.lastName}`.slice(
                0,
                3
              ) +
              "-" +
              currentCase.id
            }}
          </span>
        </span>
      </div>
      <div class="d-flex flex-row align-center secondary--text">
        <span class="ml-4" style="font-size: 30px">
          Client:
          {{
            `${currentCase.clientInfo.firstName} ${currentCase.clientInfo.lastName}`
          }}
        </span>
      </div>
    </div>

    <SubTopNav :value="2" />

    <div
      v-if="currentCase.vcDateConsentDrafted"
      class="mb-10 d-flex justify-center"
    >
      <div
        class="ledger-nav mr-10 d-flex align-center"
        :class="{ active: ledgerType === 0 }"
        @click="ledgerType = 0"
      >
        <span class="overline font-weight-medium secondary--text"
          >Old Ledger</span
        >
      </div>

      <div
        class="ledger-nav mr-10 d-flex align-center"
        :class="{ active: ledgerType === 1 }"
        @click="ledgerType = 1"
      >
        <span class="overline font-weight-medium secondary--text"
          >New Ledger</span
        >
      </div>
    </div>

    <v-container fluid class="case-view">
      <v-row class="mb-8">
        <RentLedgerView
          v-if="ledgerType === 0"
          :current-case="currentCase"
          :prevRouteName="prevRoute.name"
        />
        <ConsentLedgerView
          v-if="ledgerType === 1"
          :current-case="currentCase"
          :prevRouteName="prevRoute.name"
        />
      </v-row>
    </v-container>

    <v-card-actions
      style="background-color: #fafbfc; border-radius: 0px 0px 10px 10px"
      class="justify-end pr-16 mr-16"
    >
      <v-btn
        rounded
        color="accent_light"
        class="btn--plain capital--btn"
        text
        @click="goBack"
      >
        {{ fromCaseProgressBar ? "Go Back To Case Overview" : "Go Back" }}
      </v-btn>
      <v-btn
        v-if="fromCaseProgressBar"
        rounded
        color="accent_light"
        class="px-8 white--text capital--btn"
        depressed
        @click="next"
      >
        Continue To
        {{
          nextStep === "courtOutcome"
            ? "Case Management Outcome"
            : "Post Trial Actions"
        }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import RentLedgerView from "@/components/RentLedgerView";
import ConsentLedgerView from "@/components/ConsentLedgerView";
import SubTopNav from "@/components/SubTopNav.vue";
import { mapGetters } from "vuex";

export default {
  name: "CaseLedger",
  components: {
    RentLedgerView,
    ConsentLedgerView,
    SubTopNav,
  },
  data() {
    return {
      ledgerType: 0,
      prevRoute: { name: "" },
      nextStep: "",
    };
  },
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
      nextStepAfterLedger: "eviction/nextStepAfterLedger",
    }),
    fromCaseProgressBar() {
      return (
        this.nextStep === "courtOutcome" || this.nextStep === "trialOutcome"
      );
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {
    this.nextStep = this.nextStepAfterLedger;
    this.$store.commit("eviction/set_nextStepAfterLedger", "");
    if (this.currentCase.vcDateConsentDrafted) this.ledgerType = 1;
  },
  methods: {
    goBack() {
      this.$router.push({ name: this.prevRoute.name });
    },
    next() {
      if (this.nextStep === "courtOutcome") {
        this.$router.push({ name: "wor-court-outcome" });
      } else if (this.nextStep === "trialOutcome") {
        this.$router.push({ name: "wor-trial-action" });
      }
    },
  },
};
</script>

<style>
.rounded-card.v-card.v-sheet.theme--light {
  border-radius: 20px;
}
.active-bottom-btn {
  color: white !important;
  background-color: #4dccc4 !important;
}
.ledger-nav {
  padding: 2px 0;
  cursor: pointer;
}

.ledger-nav span {
  padding: 3px 0;
}

.ledger-nav:hover span {
  border-bottom: 3px solid #4dccc4;
  padding: 3px 0 0;
}

.ledger-nav.active span {
  border-bottom: 3px solid #4dccc4;
  padding: 3px 0 0;
}
</style>
