<template>
  <VerifiedComplaintBox
    :continue-btn-disabled="!valid"
    :vc-cancel-btn-props="{
      btnText: 'Go Back',
    }"
    :vc-continue-btn-props="{
      btnText: 'Update',
    }"
    @vcBoxContinue="next"
    @vcBoxCancel="$router.push({ name: 'case-view-overview' })"
    @vcBoxBack="goBack"
  >
    <div class="custom-title pt-8 pb-4">Trial Virtual Room</div>
    <div class="pb-4">
      Enter the zoom link, meeting ID and password that you received from the
      Court. Haven't received yet? You can always come back to this screen to
      insert the information. Once inserted, your client will be informed, and
      the information will be saved to the case.
    </div>

    <v-form ref="form" v-model="valid" class="pt-10 pb-4 text-left">
      <div class="text-left">
        <div class="font-weight-medium secondary--text pb-2">
          NJCourts Virtual Court Room
        </div>
        <v-textarea
          v-model="virtualRoom"
          rounded
          multiline
          solo
          flat
          dense
          background-color="#F0F5F6"
        />

        <div class="d-flex justify-space-around">
          <div
            class="font-weight-medium secondary--text pb-2 d-flex flex-column"
          >
            <span>Trial Date</span>
            <v-menu
              v-model="manageConfMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="manageConf"
                  append-icon="$calendar"
                  color="accent"
                  readonly
                  rounded
                  solo
                  flat
                  dense
                  background-color="#F0F5F6"
                  v-on="on"
                  @click:append="manageConfMenu = true"
                />
              </template>
              <v-date-picker
                v-model="selectedManageConf"
                no-title
                :show-current="false"
                color="primary"
                class="custom-date"
                @input="closedManageConfMenu"
                :rules="[(v) => !!v || 'Item is required']"
              />
            </v-menu>
          </div>
          <div
            class="font-weight-medium secondary--text pb-2 d-flex flex-column"
          >
            <span>Trial Time</span>
            <v-menu
              v-model="courtInfoTimeMenu"
              transition="scale-transition"
              offset-y
              max-width="300px"
              :close-on-content-click="false"
              :nudge-right="10"
              :nudge-top="25"
              rounded="xl"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="courtInfoTime"
                  append-icon="mdi-clock-time-nine-outline"
                  color="accent"
                  readonly
                  rounded
                  solo
                  flat
                  dense
                  background-color="#F0F5F6"
                  v-on="on"
                  @click:append="courtInfoTimeMenu = true"
                  :rules="[(v) => !!v || 'Item is required']"
                />
              </template>

              <EVTimePicker @meridiemClicked="evTimePickerSelected" />
            </v-menu>
          </div>
        </div>
      </div>
    </v-form>
  </VerifiedComplaintBox>
</template>

<script>
import { mapGetters } from "vuex";
import VerifiedComplaintBox from "@components/VerifiedComplaintBox";
import EVTimePicker from "@components/eviction/EVTimePicker";

export default {
  name: "TrialVirtualRoom",
  components: { VerifiedComplaintBox, EVTimePicker },
  data: () => ({
    valid: true,
    virtualRoom: "",
    manageConf: null,
    selectedManageConf: null,
    manageConfMenu: false,
    courtInfoTime: null,
    courtInfoTimeMenu: false,
  }),
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
  },
  activated() {
    window.scrollTo(0, 0);
    this.virtualRoom = this.currentCase.virtualRoom;
    this.selectedManageConf = this.currentCase.manageConf ?? null;
    this.manageConf = this.selectedManageConf
      ? this.$dayjs(this.selectedManageConf).format("MMMM DD, YYYY")
      : "";
    this.courtInfoTime = this.currentCase.courtInfoTime ?? null;
  },
  methods: {
    evTimePickerSelected(data) {
      this.courtInfoTime = data;
      this.courtInfoTimeMenu = false;
    },
    closedManageConfMenu(data) {
      this.selectedManageConf = data;
      this.manageConf = this.$dayjs(this.selectedManageConf).format(
        "MMMM DD, YYYY"
      );
      this.manageConfMenu = false;
    },
    async next() {
      const caseData = {
        manageConf: this.selectedManageConf,
        courtInfoTime: this.courtInfoTime,
        virtualRoom: this.virtualRoom,
      };

      await this.$store.dispatch("eviction/updateCase", {
        caseData,
        status: "trialVirtualRoom",
      });

      this.$router.push({ name: "case-view-overview" });
    },
    goBack() {
      this.$router.push({ name: "wor-trial" });
    },
  },
};
</script>
