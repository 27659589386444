<template>
  <svg viewBox="0 0 37 55.503">
    <path
      class="a svg-icon"
      d="M242.836,69.313h-6.064v-.736a1.6,1.6,0,0,0-1.592-1.592h-4.174V64.092a1.6,1.6,0,0,0-1.592-1.592H223.8a1.6,1.6,0,0,0-1.592,1.592v2.88h-4.174a1.6,1.6,0,0,0-1.592,1.592V69.3h-6.064a2.279,2.279,0,0,0-2.277,2.277v44.155A2.278,2.278,0,0,0,210.377,118h32.452a2.277,2.277,0,0,0,2.271-2.271V71.577A2.27,2.27,0,0,0,242.836,69.313Zm-18.319-5.049h4.174a.169.169,0,0,1,.171.165v2.144h-4.51V64.428h-.006A.169.169,0,0,1,224.516,64.263Zm17.666,50.384a.319.319,0,0,1-.324.317H211.348a.314.314,0,0,1-.317-.317V72.992a.324.324,0,0,1,.317-.324h5.29V75.1a1.565,1.565,0,0,0,1.567,1.56h16.81a1.559,1.559,0,0,0,1.56-1.56V72.668h5.29a.325.325,0,0,1,.324.324v41.656ZM223.432,90.765a26.431,26.431,0,0,0-5.969,5.132l-2.226-1.744a8.166,8.166,0,0,0-1.25,1.078l4.06,4.123a.149.149,0,0,0,.114.051.038.038,0,0,0,.025-.006.144.144,0,0,0,.12-.1,24.735,24.735,0,0,1,5.569-7.707.151.151,0,0,0,.038-.184,4.974,4.974,0,0,0-.482-.641Zm15.427,3.267H226.806a.357.357,0,0,0-.362.362v1.4a.357.357,0,0,0,.362.362h12.052a.361.361,0,0,0,.362-.362v-1.4A.361.361,0,0,0,238.858,94.032ZM223.425,81.009a26.43,26.43,0,0,0-5.969,5.132L215.23,84.4a8.166,8.166,0,0,0-1.25,1.078l4.06,4.123a.148.148,0,0,0,.114.051.038.038,0,0,0,.025-.006.144.144,0,0,0,.121-.1,24.722,24.722,0,0,1,5.569-7.7.151.151,0,0,0,.038-.184,4.161,4.161,0,0,0-.482-.647Zm15.433,3.267H226.806a.357.357,0,0,0-.362.362v1.4a.361.361,0,0,0,.362.362h12.052a.361.361,0,0,0,.362-.362v-1.4A.361.361,0,0,0,238.858,84.276Zm-15.433,16.251a26.429,26.429,0,0,0-5.969,5.132l-2.226-1.744a8.532,8.532,0,0,0-1.25,1.078l4.06,4.123a.149.149,0,0,0,.114.051.038.038,0,0,0,.025-.006.144.144,0,0,0,.121-.1,24.734,24.734,0,0,1,5.569-7.707.151.151,0,0,0,.038-.184A4.127,4.127,0,0,0,223.425,100.528Zm15.433,3.26H226.806a.357.357,0,0,0-.362.362v1.4a.361.361,0,0,0,.362.362h12.052a.361.361,0,0,0,.362-.362v-1.4A.366.366,0,0,0,238.858,103.788Z"
      transform="translate(-208.1 -62.5)"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
