import Vue from "vue";
import useRequest from "@/utils/useRequest";
import router from "@/router";
import { store } from "@/store";
import updateCaseSteps from "@/utils/updateCaseSteps";

export default {
  namespaced: true,
  state: {
    currentProperty: {
      propertyId: "",
      propertyType: "",
      ownershipType: "",
      clientType: "",
      unitNo: "",
      streetAddress: "",
      city: "",
      subdivision: "",
      state: "New Jersey",
      zipcode: "",
      county: "",
      contactInfoId: "",
      contactInfo: {},
      deedNames: [""],
      registrationFiled: "",
      registrationFiles: [],
      status: "type",
    },
    properties: [],
  },
  mutations: {
    set_current_property(state, property) {
      console.log("set_current_property was called. property:", property);
      Vue.set(state, "currentProperty", property);
    },
    update_current_property(state, updatedFields) {
      const currentPropertyInstance = {
        ...state.currentProperty,
        ...updatedFields,
      };
      console.log(
        "update_current_property, updating property to:",
        currentPropertyInstance
      );
      Vue.set(state, "currentProperty", currentPropertyInstance);

      if (!store.getters.isAuthenticated) {
        sessionStorage.setItem(
          "property",
          JSON.stringify(currentPropertyInstance)
        );
      }
      if (router.currentRoute.name.includes("vc")) updateCaseSteps();
    },
    set_properties(state, properties) {
      Vue.set(state, "properties", properties);
    },
    init_property(state) {
      Vue.set(state, "currentProperty", {
        propertyId: "",
        propertyType: "",
        ownershipType: "",
        clientType: "",
        unitNo: "",
        streetAddress: "",
        city: "",
        subdivision: "",
        state: "New Jersey",
        zipcode: "",
        county: "",
        contactInfoId: "",
        contactInfo: {},
        deedNames: [""],
        registrationFiled: "",
        registrationFiles: [],
        status: "contact",
      });
    },
  },
  actions: {
    // eslint-disable-next-line
    async loadProperties({ commit }, clientId) {
      const { data, error } = await useRequest({
        path: `properties?clientId=${clientId}`,
      });
      if (error) return;
      await commit("set_properties", data);
      return data;
    },
    async loadProperty({ commit }, propertyId) {
      console.log("loadProperty was called, propertyId:", propertyId);

      const { data, error } = await useRequest({
        path: `property/${propertyId}`,
      });
      if (error) return;
      await commit("set_current_property", data);
    },
    async saveProperty(
      { commit, state, dispatch, rootGetters },
      { propertyData, caseData }
    ) {
      console.log(
        "saveProperty was called, propertyData:",
        propertyData,
        "caseData:",
        caseData
      );
      const { propertyId } = state.currentProperty;
      const clientId = rootGetters.client.id;
      //* create or update property in the db
      const { data: res, error } = await useRequest({
        path: `properties/save`,
        method: "post",
        data: {
          property: { ...propertyData, propertyId },
          clientId: clientId,
        },
      });

      if (!res.success) {
        store.commit(
          "global/set_global_error",
          { message: res.msg },
          { root: true }
        );
        return false;
      }
      if (error) return false;
      const savedProperty = res.data;
      commit("update_current_property", savedProperty);

      //* Create case if not yet created.
      const { name, params } = router.currentRoute;
      const isNewCase = name.includes("vc") && params.id === "new";
      if (isNewCase) {
        const newPropertyId = propertyId || savedProperty.propertyId;
        console.log("creating a new case with propertyId:", newPropertyId);
        dispatch(
          "eviction/createCase",
          {
            caseData: {
              propertyId: newPropertyId,
            },
            logMessage: "property.js at line 183",
          },
          { root: true }
        );
      } else {
        let cases = rootGetters["eviction/cases"];
        let currentCase = cases.find(
          (item) => item.id === rootGetters["eviction/currentCase"].id
        );
        currentCase = { ...currentCase, ...caseData };
        cases = cases.filter((item) => item.id !== currentCase.id);
        cases = [...cases, currentCase];
        cases.sort((a, b) => (a.updated_at < b.updated_at ? 1 : -1));
        await store.commit("eviction/set_cases", cases);
      }

      return savedProperty;
    },
    async getRegistrationDocs({ commit, rootGetters }, { propertyId }) {
      if (!rootGetters.isAuthenticated) return;
      await useRequest({
        path: `registration/${propertyId}`,
        onSuccess: (registrationFiles) =>
          commit("update_current_property", { registrationFiles }),
      });
    },
  },
  getters: {
    currentProperty: (state) => state.currentProperty,
    properties: (state) => state.properties,
  },
};
