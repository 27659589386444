import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      currentCase: "eviction/currentCase",
    }),
    isInStandaloneForms() {
      return this.$route.name.startsWith("saf-");
    },
    isCLRSInFlow() {
      if (this.isInStandaloneForms) return true;
      const { clrsDraft, clrsSubmission, clrsExemption } = this.currentCase;

      return clrsDraft && (clrsSubmission || clrsExemption);
    },
  },
  methods: {
    routerPushCondition(destinationPage) {
      return this.isInStandaloneForms
        ? `saf-${destinationPage}`
        : `vc-${destinationPage}`;
    },
  },
};
