<template>
  <svg viewBox="0 0 35 24.889">
    <path
      class="a svg-icon"
      d="M38.935,163.972a.6.6,0,0,1-.07-.721,7.953,7.953,0,0,0,1.225-4.245,8.028,8.028,0,1,0-8.028,8.028,7.951,7.951,0,0,0,4.529-1.414.57.57,0,0,1,.71.056l2.782,2.782c.017.018.038.035.059.053s.21.2.4.378l.164.164a.473.473,0,0,0,.665,0l.994-.994a.473.473,0,0,0,0-.665l-3.426-3.423Zm-2.019-1.225a6.29,6.29,0,0,1-.892.938,6.191,6.191,0,1,1,.892-.938Zm-14.37-3.528a9.239,9.239,0,0,1,4.06-7.657H8.848c-1.337,0-1.407.647-1.33,1.438l1.193,13.061c.073.8.259,1.445,1.6,1.445H27.684A9.228,9.228,0,0,1,22.546,159.219Zm11.643-10.852a1.832,1.832,0,0,0-1.753-1.162H22.553a4.087,4.087,0,0,1-2.5-1.025l-.861-.854a4.087,4.087,0,0,0-2.5-1.025H11.974a1.669,1.669,0,0,0-1.624,1.438l-.423,3.78H34.48l-.29-1.151Z"
      transform="translate(-7.498 -144.3)"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
