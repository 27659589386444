<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="16.842"
    viewBox="0 0 9 16.842"
  >
    <defs />
    <path
      class="custom-sort-svg-icon"
      d="M345.239,216.115V204.55h3.074c.4,0,.5-.23.213-.514l-3.823-3.823a.727.727,0,0,0-1.027,0l-3.823,3.823c-.283.284-.188.514.213.514h3.076v11.565a.727.727,0,0,0,.727.727h.643A.725.725,0,0,0,345.239,216.115Z"
      transform="translate(-339.689 -200)"
    />
  </svg>
</template>
<script>
export default {};
</script>

<style>
.custom-sort-svg-icon {
  fill: currentColor;
}
</style>
