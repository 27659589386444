import Vue from "vue";
import VueRouter from "vue-router";

import AuthLoading from "@/components/views/AuthLoading";
import Register from "@/components/views/Register.vue";
import Profile from "@/components/views/Profile.vue";
import Main from "@/components/views/Main.vue";
import CaseView from "@/components/views/CaseView.vue";
import MatterManagement from "@/components/views/MatterManagement.vue";
import CaseLedger from "@/components/views/CaseLedger.vue";
import VerifiedComplaint from "@/components/views/VerifiedComplaint";
import NewPropertyForm from "@/components/views/NewPropertyForm.vue";
import Attorneys from "@/components/views/Attorneys.vue";
import Messages from "@/components/views/Messages.vue";
import Calendar from "@/components/views/Calendar.vue";

import Type from "@/components/subpages/newProperty/Type";
import Address from "@/components/subpages/newProperty/Address";
import Owner from "@/components/subpages/newProperty/Owner";
import Contact from "@/components/subpages/newProperty/Contact";

import SelectAttorney from "@/components/subpages/verifiedComplaint/SelectAttorney";
import BeforeProperty from "@/components/subpages/verifiedComplaint/BeforeProperty";
import SelectProperty from "@/components/subpages/verifiedComplaint/SelectProperty";
import DocumentIntake from "@/components/subpages/verifiedComplaint/DocumentIntake";
import Tenants from "@/components/subpages/verifiedComplaint/Tenants";
import LeaseDetails from "@/components/subpages/verifiedComplaint/LeaseDetails";
import PastDueRent from "@/components/subpages/verifiedComplaint/PastDueRent";
import AdditionalCharges from "@/components/subpages/verifiedComplaint/AdditionalCharges";
import AdditionalChargesExtended from "@/components/subpages/verifiedComplaint/AdditionalChargesExtended";
import Documents from "@/components/subpages/verifiedComplaint/Documents";
import UploadDocuments from "@/components/subpages/UploadDocuments";
import Confirmation from "@/components/subpages/verifiedComplaint/Confirmation";
import Verification from "@/components/subpages/verifiedComplaint/Verification";
import SuitAmountSummary from "@/components/subpages/verifiedComplaint/SuitAmountSummary";
import LegalFees from "@/components/subpages/verifiedComplaint/LegalFees";
import Filing from "@/components/subpages/verifiedComplaint/Filing";
import Signature from "@/components/subpages/verifiedComplaint/Signature";

import CourtOutcome from "@components/subpages/warrantOfRemoval/CourtOutcome";
import FiledConsentJudgment from "@components/subpages/warrantOfRemoval/FiledConsentJudgment";
import MoveOutDate from "@/components/subpages/warrantOfRemoval/MoveOutDate";
import PaymentPlan from "@/components/subpages/warrantOfRemoval/PaymentPlan";
import RentConsentTerm from "@/components/subpages/warrantOfRemoval/RentConsentTerm";
import AdditionalConditions from "@/components/subpages/warrantOfRemoval/AdditionalConditions";
import ReviewAndSign from "@/components/subpages/warrantOfRemoval/ReviewAndSign";
import Trial from "@/components/subpages/warrantOfRemoval/Trial";
import TrialVirtualRoom from "@/components/subpages/warrantOfRemoval/TrialVirtualRoom";
import TrialAction from "@/components/subpages/warrantOfRemoval/TrialAction";
import RentLedger from "@/components/subpages/warrantOfRemoval/RentLedger";
import ConsentLedger from "@/components/subpages/warrantOfRemoval/ConsentLedger";
import ConfirmDates from "@/components/subpages/warrantOfRemoval/ConfirmDates";
import TrialReviewAndSign from "@/components/subpages/warrantOfRemoval/TrialReviewAndSign";
import ConfirmBreach from "@/components/subpages/warrantOfRemoval/ConfirmBreach";
import BreachPaymentsPlan from "@/components/subpages/warrantOfRemoval/BreachPaymentsPlan";
import BreachOtherConditions from "@/components/subpages/warrantOfRemoval/BreachOtherConditions";

import { store } from "./store";

Vue.use(VueRouter);

const routes = [
  /* AUTHORIZATION ROUTES - START */
  {
    path: "/login",
    name: "login",
    component: AuthLoading, // Login,
    meta: {
      isAuthRoute: true,
    },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: {
      isAuthRoute: true,
    },
  },
  /* AUTHORIZATION ROUTES - END */

  {
    path: "/",
    name: "main",
    component: Main,
    meta: {
      title: "FileEvictions | Dashboard",
    },
  },
  {
    path: "/case-view/:id/overview",
    name: "case-view-overview",
    component: CaseView,
    meta: {
      title: "FileEvictions | Single Case",
    },
  },
  {
    path: "/case-view/:id/matter-management",
    name: "case-view-management",
    component: MatterManagement,
    meta: {
      title: "FileEvictions | Single Case",
    },
  },
  {
    path: "/case-view/:id/ledger",
    name: "case-view-ledger",
    component: CaseLedger,
    meta: {
      title: "FileEvictions | Single Case",
    },
  },

  {
    path: "/attorneys",
    name: "attorneys",
    component: Attorneys,
    meta: {
      title: "FileEvictions | Attorneys",
    },
  },
  {
    path: "/messages",
    name: "messages",
    component: Messages,
    meta: {
      title: "FileEvictions | Messages",
    },
  },
  {
    path: "/calendar",
    name: "calendar",
    component: Calendar,
    meta: {
      title: "FileEvictions | Calendar",
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: {
      title: "FileEvictions | Profile",
    },
  },
  {
    path: "/verified-complaint/:id",
    name: "verified-complaint",
    component: VerifiedComplaint,
    children: [
      {
        path: "select-attorney",
        alias: "/",
        name: "vc-select-attorney",
        component: SelectAttorney,
      },
      {
        path: "before-property",
        name: "vc-before-property",
        component: BeforeProperty,
      },
      {
        path: "select-property",
        name: "vc-select-property",
        component: SelectProperty,
      },
      {
        path: "property",
        name: "vc-new-property",
        component: NewPropertyForm,
        children: [
          {
            path: "contact",
            alias: "/",
            name: "vc-client-contact",
            component: Contact,
          },
          {
            path: "address",
            name: "vc-property-address",
            component: Address,
          },
          {
            path: "type",
            name: "vc-property-type",
            component: Type,
          },
          {
            path: "owner",
            name: "vc-property-owner",
            component: Owner,
          },
        ],
      },
      {
        path: "document-intake",
        name: "vc-document-intake",
        component: DocumentIntake,
      },
      {
        path: "tenants",
        name: "vc-tenants",
        component: Tenants,
      },
      {
        path: "lease-details",
        name: "vc-lease-details",
        component: LeaseDetails,
      },
      {
        path: "past-due-rent",
        name: "vc-past-due-rent",
        component: PastDueRent,
        props: true,
      },
      {
        path: "additional-charges",
        name: "vc-additional-charges",
        component: AdditionalCharges,
      },
      {
        path: "additional-charges-extended",
        name: "vc-additional-charges-extended",
        component: AdditionalChargesExtended,
      },
      {
        path: "documents",
        name: "vc-documents",
        component: Documents,
      },
      {
        path: "upload-documents",
        name: "vc-upload-documents",
        component: UploadDocuments,
      },
      {
        path: "confirmation",
        name: "vc-confirmation",
        component: Confirmation,
      },
      {
        path: "verification",
        name: "vc-verification",
        component: Verification,
      },
      {
        path: "certification",
        name: "vc-certification",
        component: () => import("@/components/views/Certification"),
      },
      {
        path: "lease-registration",
        name: "vc-lease-registration",
        component: () => import("@/components/views/LeaseAndRegistration"),
      },
      {
        path: "suit-summary",
        name: "vc-suit-summary",
        component: SuitAmountSummary,
      },
      {
        path: "legal-fees",
        name: "vc-legal-fees",
        component: LegalFees,
      },
      {
        path: "filing",
        name: "vc-filing",
        component: Filing,
      },
      {
        path: "signature",
        name: "vc-signature",
        component: Signature,
        props: true,
      },
    ],
  },
  {
    path: "/single-form",
    name: "single-forms",
    component: () => import("@views/StandAloneForms"),
    children: [
      {
        path: "client-contact",
        name: "single-client-contact",
        component: Contact,
      },
    ],
  },
  {
    path: "/verified-complaint/:id/single",
    name: "stand-alone-forms",
    component: () => import("@views/StandAloneForms"),
    children: [
      {
        path: "registration",
        name: "saf-lease-registration",
        component: () => import("@views/LeaseAndRegistration"),
        meta: "single",
      },
      {
        path: "signature",
        name: "saf-signature",
        component: Signature,
        meta: "single",
      },
      {
        path: "filed-verified-complaint",
        name: "saf-filed-verified-complaint",
        component: () => import("@views/FiledVerifiedComplaint"),
        meta: "single",
      },
      {
        path: "terms-consent-judgment",
        name: "saf-terms-consent-judgment",
        component: () => import("@views/TermsConsentJudgment"),
        meta: "single",
      },
      {
        path: "court-information",
        name: "saf-court-information",
        component: () => import("@views/CourtInformation"),
        meta: "single",
      },
      {
        path: "virtual-room",
        name: "saf-virtual-room",
        component: () => import("@views/CaseManagementVirtualRoom"),
        meta: "single",
      },
      {
        path: "before-select-opposing-counsel",
        name: "before-select-opposing-counsel",
        component: () => import("@views/BeforeSelectOpposingCounsel"),
        meta: "single",
      },
      {
        path: "select-opposing-counsel",
        name: "select-opposing-counsel",
        component: () => import("@views/SelectOpposingCounsel"),
        meta: "single",
      },
      {
        path: "opposing-counsel-info",
        name: "opposing-counsel-info",
        component: () => import("@views/OpposingCounselInfo"),
        meta: "single",
      },
    ],
  },
  {
    path: "/warrant-of-removal/:id",
    name: "warrant-of-removal",
    component: VerifiedComplaint,
    children: [
      {
        path: "court-outcome",
        name: "wor-court-outcome",
        component: CourtOutcome,
      },
      {
        path: "filed-consent-judgment",
        name: "wor-filed-consent-judgment",
        component: FiledConsentJudgment,
        meta: "wor",
      },
      {
        path: "trial",
        name: "wor-trial",
        component: Trial,
      },
      {
        path: "trial-virtual-room",
        name: "wor-trial-virtual-room",
        component: TrialVirtualRoom,
        meta: "wor",
      },
      {
        path: "trial-action",
        name: "wor-trial-action",
        component: TrialAction,
        meta: "wor",
      },
      {
        path: "trial-rent-ledger",
        name: "wor-trial-rent-ledger",
        component: RentLedger,
        meta: "wor",
      },
      {
        path: "trial-filed-consent-judgment",
        name: "wor-trial-filed-consent-judgment",
        component: FiledConsentJudgment,
        meta: "wor",
      },
      {
        path: "consent-ledger",
        name: "wor-trial-consent-ledger",
        component: ConsentLedger,
        meta: "wor",
      },
      {
        path: "trial-confirm-breach",
        name: "wor-trial-confirm-breach",
        component: ConfirmBreach,
        meta: "wor",
      },
      {
        path: "trial-breach-payment-plan",
        name: "wor-trial-breach-payment-plan",
        component: BreachPaymentsPlan,
        meta: "wor",
      },
      {
        path: "trial-breach-other-conditions",
        name: "wor-trial-breach-other-conditions",
        component: BreachOtherConditions,
        meta: "wor",
      },
      {
        path: "trial-move-out-date",
        name: "wor-trial-move-out-date",
        component: MoveOutDate,
        meta: "wor",
      },
      {
        path: "trial-confirm-dates",
        name: "wor-trial-confirm-dates",
        component: ConfirmDates,
        meta: "wor",
      },
      {
        path: "trial-payment-plan",
        name: "wor-trial-payment-plan",
        component: PaymentPlan,
        meta: "wor",
      },
      {
        path: "trial-rent-consent-term",
        name: "wor-trial-rent-consent-term",
        component: RentConsentTerm,
        meta: "wor",
      },
      {
        path: "trial-additional-condition",
        name: "wor-trial-additional-condition",
        component: AdditionalConditions,
        meta: "wor",
      },
      {
        path: "trial-signature",
        name: "wor-trial-signature",
        component: TrialReviewAndSign,
        meta: "wor",
      },
      {
        path: "move-out-date",
        name: "wor-move-out-date",
        component: MoveOutDate,
        meta: "wor",
      },
      {
        path: "payment-plan",
        name: "wor-payment-plan",
        component: PaymentPlan,
        meta: "wor",
      },
      {
        path: "rent-consent-term",
        name: "wor-rent-consent-term",
        component: RentConsentTerm,
        meta: "wor",
      },
      {
        path: "additional-condition",
        name: "wor-additional-condition",
        component: AdditionalConditions,
        meta: "wor",
      },
      {
        path: "signature",
        name: "wor-signature",
        component: ReviewAndSign,
        meta: "wor",
        props: true,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((_to, _from, next) => {
  store.commit("global/set_global_error", null, { root: true });
  if (!window.navigator.onLine) {
    store.commit(
      "global/set_global_error",
      { message: "You seem to be offline.", type: "offline" },
      { root: true }
    );
  }

  next();
});

//* Set the page title
const DEFAULT_TITLE = "FileEvictions";
router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
