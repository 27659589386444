<template>
  <div style="padding: 0 30px; max-width: 1200px; margin: 0 auto">
    <v-row>
      <v-col cols="9" class="pr-8">
        <v-card class="my-12 text-center" style="border-radius: 10px">
          <!-- Inactive components will be cached! -->
          <keep-alive>
            <router-view v-bind="{ ...this.case }" @close:form="cancel" />
          </keep-alive>
        </v-card>
      </v-col>
      <v-col cols="3" class="my-10 pl-10">
        <p class="overline info--text font-weight-bold mb-0">
          NON-PAYMENT OF RENT
        </p>
        <p class="spaced-text secondary--text mb-6">Complaint & Summons</p>
        <SideList :items="steps" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SideList from "@/components/SideList.vue";
import { mapGetters } from "vuex";

export default {
  name: "VerifiedComplaint",
  components: {
    SideList,
  },
  computed: {
    ...mapGetters({
      case: "eviction/currentCase",
      steps: "eviction/caseSteps",
    }),
  },
  watch: {
    "case.createPropertyType": function (val) {
      if (val === "Add new Property") {
        this.steps.property.displayChildren = true;
        this.steps.property.showChildren = true;
      }
    },
  },
  mounted: async function () {
    if (!this.$auth.isAuthenticated) return;
    this.$bus.on("open-cancel-dialog-verified-complaint", () => {
      this.cancelDialog = true;
    });
  },
  methods: {
    cancel() {
      if (!this.$auth.isAuthenticated) {
        //* guest user canceled, clearing sessionStorage");
        sessionStorage.clear();
        return this.$auth.loginWithRedirect();
      }
      this.$router.push("/");
    },
  },
};
</script>

<style>
.v-timeline-item__divider {
  min-width: 60px;
}
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
  left: calc(66px - 1px);
  right: initial;
}
</style>
