<template>
  <v-card
    class="secondary--text mr-4"
    width="100%"
    height="15%"
    style="box-shadow: 15px 15px 40px #00000029; border-radius: 20px"
  >
    <v-card-title class="font-weight-bold mx-4 pt-8">
      <div>Messages</div>
    </v-card-title>
    <div style="max-height: 270px; overflow: auto">
      <v-container class="pa-0">
        <message-table :isCard="true" :_messages="messages" />
      </v-container>
    </div>
    <div class="card-action-bottom">
      <v-btn
        color="accent_light"
        class="px-4 white--text"
        rounded
        depressed
        @click="goToMessages"
      >
        New Message
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import MessageTable from "@components/tables/MessageTable";
import { mapGetters } from "vuex";

export default {
  name: "MessagesCard",
  components: {
    MessageTable,
  },
  data() {
    return {
      messages: [],
    };
  },
  computed: {
    ...mapGetters({
      _messages: "message/messages",
      loaded: "message/loaded",
      currentCase: "eviction/currentCase",
    }),
  },
  async created() {
    if (!this.loaded) {
      await this.$store.dispatch("message/loadMessages");
      this.$store.commit("message/set_loaded", true);
    }
    this.messages = this._messages.filter(
      (item) => item.caseId === this.currentCase.id
    );
  },
  methods: {
    goToMessages() {
      this.$store.commit("eviction/set_current_case", {
        ...this.currentCase,
        creatingMessage: true,
      });
      this.$router.push({ name: "messages" });
    },
  },
};
</script>

<style>
.card-action-bottom {
  padding: 25px 30px;
  background: #f8fafb;
  z-index: 1000 !important;
}
</style>
