<template>
  <svg viewBox="0 0 40 30.271">
    <path
      class="a svg-icon"
      d="M23.2,137.433q-1.542-3.732-3.1-7.456a1.038,1.038,0,0,0-.58-.668,1.187,1.187,0,0,0-1.364.324q-6.4,5.616-12.808,11.216a2.147,2.147,0,0,1-.436.272,14.438,14.438,0,0,1-3.88,1.124,1.075,1.075,0,0,0-.9,1.64c.536,1.32,1.088,2.632,1.636,3.948q.606,1.452,1.9.58a13.659,13.659,0,0,1,3.184-1.8c.284-.1.4-.108.524.208.54,1.368,1.116,2.716,1.672,4.076a1.167,1.167,0,0,0,1.236.86,3.451,3.451,0,0,0,.728-.264,1.114,1.114,0,0,0,.66-1.672c-.456-1.088-.9-2.184-1.36-3.268-.116-.272-.116-.36.22-.38,4.672-.3,9.344-.624,14.02-.932,1.192-.08,1.592-.692,1.14-1.784Q24.451,140.439,23.2,137.433ZM4.786,145.569c-.532.4-1.056.82-1.628,1.268-.408-1-.788-1.94-1.18-2.88-.088-.212.008-.244.18-.26.568-.068,1.136-.132,1.7-.212a.258.258,0,0,1,.32.2c.2.52.424,1.032.64,1.552C4.874,145.345,4.934,145.457,4.786,145.569ZM28.837,127.11A12.01,12.01,0,0,0,27,125.782a1.112,1.112,0,0,0-1.208.232c-.416.352-.384.7.072,1a13.215,13.215,0,0,1,5.524,7,13.618,13.618,0,0,1,.3,7.02c-.116.58.132.856.72.768.616-.1.944-.416,1.092-1.288A13.587,13.587,0,0,0,28.837,127.11Zm4.968-3.824a15.157,15.157,0,0,0-2.312-1.672,1.4,1.4,0,0,0-1.52.292c-.524.44-.484.876.088,1.256a16.628,16.628,0,0,1,6.944,8.792,17.113,17.113,0,0,1,.372,8.824c-.144.728.168,1.076.908.964.776-.12,1.184-.52,1.376-1.616,1.148-6.7-.688-12.348-5.856-16.839Zm-8.844,6.9a1.227,1.227,0,0,0-1.4.4c-.288.436,0,.672.288.94a9.593,9.593,0,0,1,3.208,7.952c-.032.536.256.78.808.688a1.036,1.036,0,0,0,.956-1.04,10.489,10.489,0,0,0-3.072-8.228c-.272-.276-.576-.516-.792-.708Z"
      transform="translate(0.01 -121.502)"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
