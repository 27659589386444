<template>
  <svg viewBox="0 0 37 43.106">
    <path
      class="a svg-icon"
      d="M100.236,12.82c-1.72-1.677-3.427-3.371-5.134-5.065a1.854,1.854,0,0,0-1.384-.543q-10.579.006-21.158,0A1.383,1.383,0,0,0,71,8.79v32.7c0,1.155.444,1.612,1.591,1.617,1.905,0,3.815,0,5.72,0H99.176c1.207,0,1.621-.414,1.621-1.612q0-13.631.009-27.266A1.82,1.82,0,0,0,100.236,12.82ZM78.3,18.282q2.929,0,5.863,0c.353,0,.629.129.638.5.009.4-.276.53-.651.526-.991-.009-1.987,0-2.979,0-.961,0-1.923,0-2.884,0-.366,0-.66-.082-.655-.509s.319-.509.668-.509Zm15.765,16.01a.5.5,0,0,1-.539.384c-.785.009-1.569.009-2.354.009l-12.644,0c-.129,0-.259,0-.384,0a.476.476,0,0,1-.509-.517c0-.336.211-.487.53-.491.879-.009,1.763-.009,2.643-.009h5.1v0h7.4a.969.969,0,0,1,.56.082A.475.475,0,0,1,94.063,34.293Zm-.569-4.707c-1.009.013-2.017.009-3.026.009H85.8v-.009H78.35c-.366,0-.694-.047-.729-.5-.026-.349.241-.526.75-.526H93.326c.319,0,.629.017.737.388s-.138.634-.569.638Zm.03-5.126c-1.3.009-2.6,0-3.893,0H78.626c-.129,0-.259.013-.384,0-.336-.017-.616-.121-.612-.517s.28-.491.625-.491l5.19,0H85.9v0h7.5c.246,0,.487,0,.629.259.2.353-.043.737-.5.741Zm14.467-3.815q0-6.783.009-13.562a1.956,1.956,0,0,0-.608-1.5c-1.655-1.608-3.306-3.22-4.927-4.863a2.289,2.289,0,0,0-1.75-.72Q91.287.019,81.859.009c-.737,0-1.474-.017-2.211,0a1.329,1.329,0,0,0-1.461,1.47c-.013.815.013,1.634,0,2.453,0,.263.073.375.353.362.466-.017.931,0,1.392,0,.832,0,.819,0,.836-.832.017-.923-.177-.858.884-.862q6.033-.006,12.07,0c2.052,0,4.1.009,6.156,0,.418,0,.582.1.569.565-.034,1.138-.022,2.276-.013,3.414,0,.672.276.923.935.927,1.168,0,2.341.026,3.509,0,.44-.013.535.138.53.547-.017,2.707-.009,5.419-.009,8.126q-.006,7.979-.009,15.963c0,1.16.009,1.16-1.173,1.168a.7.7,0,0,1-.095,0c-.263-.022-.384.086-.379.358q.013.938,0,1.875c0,.267.108.371.371.371.866-.009,1.733.017,2.6-.017a1.23,1.23,0,0,0,1.263-1.25,4.43,4.43,0,0,0,.009-.479q.006-6.757,0-13.51Z"
      transform="translate(-71)"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style>
.svg-icon {
  fill: currentColor;
  width: 100%;
}
</style>
