import Vue from "vue";
import App from "./App.vue";
import Loading from "./Loading.vue";
import { store } from "./store";
import router from "./router.js";
import axios from "axios";
import authConfig from "../auth_config.json";
import { useAuth0 } from "./auth";
import vuetify from "./plugins/vuetify";
import VueDayjs from "./plugins/dayjs-plugin.js";
import convertToCurrency from "./plugins/convertToCurrency.js";
import convertDate from "./plugins/convertDate.js";
import helpers from "./plugins/helpers.js";
import stateHelper from "./plugins/stateHelper.js";
import createAvatarBackgroundColor from "./plugins/createAvatarBG";
import VueBus from "vue-bus";
import VueMq from "vue-mq";
import vuescroll from "vuescroll";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import "@/components";
import "@/assets/styles/index.scss";

Vue.use(VueBus);
Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1250,
    desktop: Infinity,
  },
});
Vue.use(vuescroll);

Vue.use(VueDayjs);
Vue.use(convertToCurrency);
Vue.use(convertDate);
Vue.use(helpers);
Vue.use(stateHelper);
Vue.use(createAvatarBackgroundColor);

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

const auth0Optopns = {
  domain: authConfig.domain,
  clientId: authConfig.clientId,
};
Vue.prototype.$auth = useAuth0(auth0Optopns);

const app = new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
});

export const auth0Instance = app.constructor.prototype.$auth;

//* Show loading while auth innitializes.
const loadingApp = new Vue({
  render: (h) => h(Loading),
});
loadingApp.$mount("#app");

//* Replace loader whn auth init is done.
auth0Instance.initAuth().then(() => {
  app.$mount("#app");
});
