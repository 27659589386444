<template>
  <div>
    <!-- Inactive components will be cached! -->
    <keep-alive>
      <router-view @close:form="$emit('close:form')" />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "NewPropertyForm",
};
</script>
