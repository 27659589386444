<template>
  <div class="mx-auto secondary--text">
    <div style="width: 100%" class="pb-4 mx-auto text-left">
      <div class="custom-title pb-4">Consent Ledger</div>
      <div class="pb-4">
        <div>
          We created a ledger for the terms of the consent to enter Judgment
          that you agreed to with your tenant. We also include any future rents
          which are part of this agreement.
        </div>
        <div>
          The future rent will be entered into the ledger as the rent become
          due. This ledger will need to be updated if the matter goes to a
          breach of consent to enter Judgment.
        </div>
      </div>

      <!-- Rent Ledger Start -->
      <div class="mt-8 mb-4">
        <v-row>
          <v-col class="d-flex flex-column font-weight-bold">
            <span class="mr-4 mb-4"
              >Consent Drafted Date:
              {{
                $dayjs(currentCase.vcDateConsentDrafted).format("MM/DD/YYYY")
              }}</span
            >
            <span class="mr-4">Last Breach Date: {{ lastBreachDate }}</span>
          </v-col>
        </v-row>
      </div>
      <div class="ledger-table" style="max-height: 420px; overflow: auto">
        <vue-scroll :ops="ops">
          <v-data-table
            :headers="headers"
            :items="ledgerData"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            disable-sort
          >
            <template v-slot:item.amount="props">
              <div class="d-flex">
                <div>
                  {{
                    !props.item.amount
                      ? ""
                      : convertToCurrency(props.item.amount)
                  }}
                </div>
              </div>
            </template>
            <template v-slot:item.payment="props">
              <div class="d-flex">
                <v-edit-dialog
                  v-if="props.item.payment"
                  large
                  persistent
                  @save="savePayAmount()"
                >
                  <div>
                    {{ convertToCurrency(0 - parseFloat(props.item.payment)) }}
                    <v-icon size="14" color="#4dccc4" class="ml-1">
                      $pencil
                    </v-icon>
                  </div>
                  <template v-slot:input>
                    <div class="mt-4">Change amount</div>
                    <v-text-field
                      v-model="props.item.payment"
                      label="Edit"
                      single-line
                      autofocus
                      type="number"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
                <v-icon
                  v-if="props.item.payment"
                  size="18"
                  color="#4dccc4"
                  class="ml-1 mt-1"
                  @click="clearPayAmount(props.item)"
                >
                  mdi-delete
                </v-icon>
              </div>
            </template>
            <template v-slot:item.totalBalance="props">
              <span>{{ convertToCurrency(props.item.totalBalance) }}</span>
            </template>
          </v-data-table>
        </vue-scroll>
      </div>
      <div class="d-flex align-center mt-3 mb-3">
        <div class="ml-1 info--text text-uppercase">
          <v-btn
            class="btn--plain"
            color="accent"
            icon
            tile
            @click="addPayDialogOpen = true"
          >
            <v-icon style="font-size: 28px"> mdi-plus-circle-outline </v-icon>
          </v-btn>
          Add Payments
        </div>
      </div>

      <h2 class="text-right mt-4">
        Total due: {{ convertToCurrency(totalDue) }}
      </h2>

      <div class="mt-8 mb-4">
        <v-row>
          <v-col cols="3">Total Base Rent</v-col>
          <v-col cols="2">Late Fees</v-col>
          <v-col cols="2">Court Fees</v-col>
          <v-col cols="2">Attorney Fees</v-col>
          <v-col cols="3">Total Balance</v-col>
        </v-row>
        <v-row class="font-weight-bold">
          <v-col cols="3">{{ convertToCurrency(totalBaseRent) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(lateFee) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(courtFee) }}</v-col>
          <v-col cols="2">{{ convertToCurrency(attorneyFee) }}</v-col>
          <v-col cols="3">{{ convertToCurrency(totalDue) }}</v-col>
        </v-row>
      </div>
      <!-- Rent Ledger End -->
    </div>

    <div class="mt-8 mb-4 font-weight-bold">Future Rents</div>
    <div class="ledger-table" style="max-height: 420px; overflow: auto">
      <vue-scroll :ops="ops">
        <v-data-table
          :headers="headers"
          :items="futureRents"
          class="elevation-1"
          hide-default-footer
          disable-pagination
          disable-sort
        >
          <template v-slot:item.amount="props">
            <div class="d-flex">
              <div>
                {{ convertToCurrency(props.item.amount) }}
              </div>
            </div>
          </template>
          <template v-slot:item.payment="props">
            <div class="d-flex">
              <v-edit-dialog
                v-if="props.item.payment"
                large
                persistent
                @save="savePayAmount()"
              >
                <div>
                  {{ convertToCurrency(0 - parseFloat(props.item.payment)) }}
                  <v-icon size="14" color="#4dccc4" class="ml-1">
                    $pencil
                  </v-icon>
                </div>
                <template v-slot:input>
                  <div class="mt-4">Change amount</div>
                  <v-text-field
                    v-model="props.item.payment"
                    label="Edit"
                    single-line
                    autofocus
                    type="number"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
              <v-icon
                v-if="props.item.payment"
                size="18"
                color="#4dccc4"
                class="ml-1 mt-1"
                @click="clearPayAmount(props.item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
          <template v-slot:item.totalBalance="props">
            <span>{{ convertToCurrency(props.item.totalBalance) }}</span>
          </template>
        </v-data-table>
      </vue-scroll>
    </div>

    <div>
      <v-dialog
        v-model="addPayDialogOpen"
        value="''"
        max-width="1000"
        persistent
      >
        <AddPayDialog
          @close:dialog="addPayDialogOpen = false"
          @add="addPayment"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import AddPayDialog from "@/components/dialogs/AddPayDialog";

export default {
  name: "ConsentLedgerView",
  props: {
    currentCase: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    AddPayDialog,
  },
  data() {
    return {
      addPayDialogOpen: false,
      headers: [
        {
          text: "Date",
          align: "start",
          value: "date",
        },
        { text: "Details", value: "details" },
        { text: "Amount Due", value: "amount" },
        { text: "Amount Paid", value: "payment" },
        { text: "Total Balance", value: "totalBalance" },
      ],
      ops: {
        vuescroll: {},
        scrollPanel: {
          speed: 100,
          easing: "easeInQuad",
        },
        rail: {
          background: "#EAEBEB",
          opacity: 1,
          size: "10px",
          specifyBorderRadius: false,
          gutterOfEnds: "10px",
          gutterOfSide: "0px",
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: "#4DCCC4",
          opacity: 1,
          size: "10px",
          minSize: 0.1,
        },
      },
      ledgerData: [],
      charges: [],
      payments: [],
      totalBaseRent: 0,
      attorneyFee: 0,
      courtFee: 0,
      lateFee: 0,
      totalDue: 0,
      futureRents: [],
    };
  },
  computed: {
    lastBreachDate() {
      if (!this.currentCase.paymentPlan) return;
      const lastConsentPayment =
        this.currentCase.paymentPlan[this.currentCase.paymentPlan.length - 1];
      const result = new Date(
        this.$dayjs(lastConsentPayment.dateDue).format("MM/DD/YYYY")
      );
      result.setDate(result.getDate() + 30);
      return this.$dayjs(result).format("MM/DD/YYYY");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    /* add data to table - START */
    this.charges = [];
    let chargeItem;

    const paymentPlan = this.currentCase.paymentPlan || [];
    for (const element of paymentPlan) {
      chargeItem = {};
      chargeItem.dateForSort = this.$dayjs(element.dateDue).format(
        "YYYY-MM-DD"
      );
      chargeItem.date = this.$dayjs(element.dateDue).format("MM/DD/YYYY");
      chargeItem.details = "Consent amount";
      chargeItem.amount = parseFloat(element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
    }

    const consentTermRents = this.currentCase.consentTermRents || [];
    for (const element of consentTermRents) {
      chargeItem = {};
      chargeItem.dateForSort = this.$dayjs(element.dueDate).format(
        "YYYY-MM-DD"
      );
      chargeItem.date = this.$dayjs(element.dueDate).format("MM/DD/YYYY");
      chargeItem.details = "Rent";
      chargeItem.amount = parseFloat(element.amount);
      chargeItem.payment = 0;
      chargeItem.totalBalance = 0;
      this.charges.push(chargeItem);
    }

    this.payments = this.currentCase.consentPayments || [];

    this.applyChangeInCharge();

    if (!this.currentCase.paymentPlan) return;
    // calculate future rents
    const lastPaymentPlanDate =
      this.currentCase.paymentPlan.slice(-1)[0].dateDue;
    const today = this.$dayjs().format("YYYY-MM-DD");
    if (today > lastPaymentPlanDate) return;
    const lastPaymentPlanMonth = parseInt(lastPaymentPlanDate.split("-")[1]);
    const todayMonth = parseInt(this.$dayjs().format("MM")) + 1;
    const rentDueBy = parseInt(this.currentCase.rentDueBy);
    if (lastPaymentPlanMonth >= todayMonth) {
      for (let i = todayMonth; i <= lastPaymentPlanMonth; i++) {
        const baseDate = `${today.split("-")[0]}-${i > 9 ? i : `0${i}`}-${
          rentDueBy > 9 ? rentDueBy : `0${rentDueBy}`
        }`;
        const rent = {
          date: this.$dayjs(baseDate).format("MM/DD/YYYY"),
          details: "Rent",
          amount: this.currentCase.rentAmount,
          payment: 0,
        };
        this.futureRents.push(rent);
      }
    } else {
      for (let i = todayMonth; i <= 12; i++) {
        const baseDate = `${today.split("-")[0]}-${i > 9 ? i : `0${i}`}-${
          rentDueBy > 9 ? rentDueBy : `0${rentDueBy}`
        }`;
        const rent = {
          date: this.$dayjs(baseDate).format("MM/DD/YYYY"),
          details: "Rent",
          amount: this.currentCase.rentAmount,
          payment: 0,
        };
        this.futureRents.push(rent);
      }
      // next year
      const nextYear = parseInt(today.split("-")[2]) + 1;
      for (let i = 1; i <= lastPaymentPlanMonth; i++) {
        const baseDate = `${nextYear}-${i > 9 ? i : `0${i}`}-${
          rentDueBy > 9 ? rentDueBy : `0${rentDueBy}`
        }`;
        const rent = {
          date: this.$dayjs(baseDate).format("MM/DD/YYYY"),
          details: "Rent",
          amount: this.currentCase.rentAmount,
          payment: 0,
        };
        this.futureRents.push(rent);
      }
    }
    this.futureRents = this.futureRents.map((item, index) => {
      return {
        ...item,
        totalBalance: this.currentCase.rentAmount * (index + 1),
      };
    });
  },
  methods: {
    async saveData() {
      await this.$store.dispatch("eviction/updateCase", {
        caseData: {
          consentPayments: this.payments,
        },
        status: this.currentCase.status,
      });
    },
    formatDay(day) {
      const number = parseInt(day);
      let suffix;
      if (number > 3 && number < 21) suffix = "th";
      switch (number % 10) {
        case 1:
          suffix = "st";
          break;
        case 2:
          suffix = "nd";
          break;
        case 3:
          suffix = "rd";
          break;
        default:
          suffix = "th";
          break;
      }
      return day + suffix;
    },
    applyChangeInCharge() {
      this.payments = this.payments.map((item) => {
        return {
          ...item,
          dateForSort: this.$dayjs(item.date).format("YYYY-MM-DD"),
          amount: 0,
        };
      });
      this.ledgerData = [...this.charges, ...this.payments];

      this.ledgerData.sort((a, b) =>
        a.dateForSort > b.dateForSort
          ? 1
          : b.dateForSort > a.dateForSort
          ? -1
          : 0
      );
      this.totalBaseRent = this.ledgerData.reduce(
        (result, item) => result + item.amount - item.payment,
        0
      );

      this.totalDue = this.ledgerData.reduce(
        (result, item) => result + item.amount - item.payment,
        0
      );

      const ledgerData = this.ledgerData.slice();
      for (let i = 0; i < ledgerData.length; i++) {
        if (i === 0) {
          ledgerData[i].totalBalance =
            ledgerData[i].amount - ledgerData[i].payment;
        } else {
          ledgerData[i].totalBalance =
            ledgerData[i - 1].totalBalance +
            ledgerData[i].amount -
            ledgerData[i].payment;
        }
      }
      this.ledgerData = ledgerData;
      this.saveData();
    },
    addPayment(newPayments) {
      this.payments = [...this.payments, ...newPayments];
      this.addPayDialogOpen = false;
      this.applyChangeInCharge();
    },
    savePayAmount() {
      this.applyChangeInCharge();
    },
    clearPayAmount(item) {
      const index = this.payments.indexOf(item);
      this.payments.splice(index, 1);
      this.applyChangeInCharge();
    },
  },
};
</script>

<style scoped>
.ledger-table {
  border-top: 1px solid lightgray !important;
}
</style>
