var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"rounded-card pt-2",staticStyle:{"box-shadow":"15px 15px 40px #00000029","min-width":"300px"}},[_c('v-card-title',{staticClass:"font-weight-bold secondary--text ml-8",staticStyle:{"font-size":"1.25rem"}},[_vm._v("Case Progress")]),_c('v-list',{staticClass:"case-progress"},_vm._l((_vm.steps),function(item,index){return _c('div',{key:index},[_c('v-list-item',{staticClass:"py-4 px-12 d-flex justify-space-between",class:[
            item.completed
              ? 'case-progress-complete'
              : 'case-progress-incomplete',
          ],on:{"click":function($event){return _vm.goToStep(index)}}},[_c('div',{class:item.completed ? 'white--text' : 'secondary--text'},[_c('v-icon',{staticClass:"pr-2",attrs:{"color":item.completed ? 'white' : 'primary'}},[_vm._v(" mdi-"+_vm._s(item.completed ? "check-circle" : "checkbox-blank-circle-outline")+" ")]),_vm._v(" "+_vm._s(item.label)+" "),(
                item.label === 'Filed Verified Complaint' &&
                _vm.currentCase.vcDateFiled
              )?_c('span',[_vm._v(" ("+_vm._s(_vm.$dayjs(_vm.currentCase.vcDateFiled).format("MMMM DD, YYYY"))+") ")]):_vm._e()],1)])],1)}),0)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }